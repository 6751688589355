import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CartEntityState,
  cartMaterialAdapter,
  CartMaterialState,
  CartState,
  initialCartMaterialState,
} from './cart.state';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { Dictionary } from '@ngrx/entity';
import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from '../material-info/material-info.state';
import { selectInventoryAvailabilityRecordStates } from '../inventory-availability/inventory-availability.selectors';
import {
  filterNonEmptyCartLines,
  getQuantityFromCartMaterial,
} from '../../../shared/utilities/cart-material-utilities';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import {
  selectCurrentSystem,
  selectIsPurchaseOrderRequired,
} from '../session/session.selectors';
import {
  SplitOrder,
  SplitOrderType,
} from '../../services/cart-order/models/cart-order';
import { InventoryAvailabilityRecordState } from '../inventory-availability/inventory-availability.state';
import {
  FulfillmentType,
  StoreFulfillment,
} from '../../services/cart/models/cart-record';
import { selectIsOnline } from '../offline-mode/offline-mode.selectors';
import { CurrentSystem } from '../../services/session/models/session-record';
import { selectMaterialNumberFromRouteParams } from '../router/router.selectors';
import { selectMaterialComparisonNumbers } from '../material-comparison/material-comparison.selectors';
import { MaterialQuantityDetail } from '../shared/shared';
import { CartMaterial } from '../../../shared/models/cart-material';
import { Cart } from '../../../shared/models/cart';

export interface CartCounts {
  cartLineCount: number;
  totalQuantity: number;
  shipmentId?: string;
  estimatedDeliveryDate?: string;
}

export interface FulfillmentStateData {
  fulfillmentType: FulfillmentType;
  hasPendingFulfillmentChange: boolean;
}

export const selectCartFeature = createFeatureSelector<CartState>('cart');

export const selectCartEntity = createSelector(
  selectCartFeature,
  (state) => state.cart,
);

export const selectLoadedCart = createSelector(
  selectCartEntity,
  (cartEntityState) => {
    if (!cartEntityState?.materials) {
      return undefined;
    }

    const cartMaterials = (cartEntityState.materials.ids as string[]).map(
      (materialNumber) => {
        const cartMaterial = cartEntityState.materials.entities[materialNumber];
        return new CartMaterial(
          cartMaterial.materialNumber,
          Object.values(cartMaterial.lines.entities),
          cartMaterial.isRestored,
          cartMaterial.isAddedFromCriticalItem,
          cartMaterial.isAddedFromMaterialComparison,
          cartMaterial.isDeleted,
          cartMaterial.originTrackingId,
        );
      },
    );

    let splitOrders: SplitOrder[] = [];
    if (cartEntityState.splitOrders) {
      splitOrders = cartEntityState.splitOrders.map((splitOrder) => ({
        orderType: splitOrder.orderType,
        customerPoNumber: splitOrder.customerPoNumber,
        materialNumbers: splitOrder.materialNumbers,
        carrierFulfillment: splitOrder.carrierFulfillment,
        truckFulfillment: splitOrder.truckFulfillment,
        storeFulfillment: splitOrder.storeFulfillment,
      }));
    }

    return new Cart(
      cartEntityState.id,
      cartMaterials,
      cartEntityState.couponCodes,
      cartEntityState.customerPoNumber,
      cartEntityState.fulfillmentType,
      splitOrders,
      cartEntityState.truckFulfillment,
      cartEntityState.storeFulfillment,
    );
  },
);

export const selectIsCartLoaded = createSelector(
  selectCartEntity,
  (cart) => !!cart,
);

export const selectIsOnlineCartLoaded = createSelector(
  selectIsCartLoaded,
  selectIsOnline,
  (isCartLoaded, isOnline) => isCartLoaded && isOnline,
);

export const selectAllCartMaterials = createSelector(
  selectCartEntity,
  (cart) =>
    cart?.materials
      ? cartMaterialAdapter.getSelectors().selectAll(cart.materials)
      : [],
);

export const selectMaterialNumbersToRefresh = createSelector(
  selectCartEntity,
  selectMaterialNumberFromRouteParams,
  selectMaterialComparisonNumbers,
  (cartEntity, routeMaterialNumber, comparisonMaterialNumbers): string[] => {
    const materialNumbers = (cartEntity?.materials?.ids as string[]) || [];
    const totalMaterialNumbers = new Set([
      ...materialNumbers,
      ...comparisonMaterialNumbers,
    ]);

    if (routeMaterialNumber) {
      totalMaterialNumbers.add(routeMaterialNumber);
    }

    return Array.from(totalMaterialNumbers);
  },
);

export const selectAllOrderableCartMaterials = createSelector(
  selectAllCartMaterials,
  selectAllMaterialInfoRecordStates,
  selectAllMaterialAvailabilityRecords,
  (cartMaterials, infos, availabilities) =>
    cartMaterials.filter(
      (material) =>
        !material.isDeleted &&
        isMaterialOrderable(material.materialNumber, infos, availabilities),
    ),
);

export const selectCartMaterialEntities = createSelector(
  selectCartEntity,
  (cart): Dictionary<CartMaterialState> =>
    cart?.materials
      ? cartMaterialAdapter.getSelectors().selectEntities(cart.materials)
      : initialCartMaterialState.entities,
);

export const selectCartMaterialNumbers = createSelector(
  selectCartEntity,
  (cart): string[] =>
    cart?.materials
      ? cartMaterialAdapter
          .getSelectors()
          .selectAll(cart.materials)
          .map((i) => i.materialNumber)
      : [],
);

export const selectCartId = createSelector(
  selectCartEntity,
  (cartEntity) => cartEntity?.id,
);

export const selectSplitOrders = createSelector(
  selectCartFeature,
  (cart) => cart?.cart?.splitOrders,
);

export const selectDropShipOrders = createSelector(
  selectSplitOrders,
  (splitOrders) =>
    splitOrders?.filter(
      (splitOrder) => splitOrder?.orderType === SplitOrderType.DROP_SHIP,
    ),
);

export const selectInvalidDropShipOrders = createSelector(
  selectDropShipOrders,
  selectIsPurchaseOrderRequired,
  (dropShipOrders, isPurchaseOrderRequired) =>
    dropShipOrders?.filter(
      (ds) =>
        !ds?.carrierFulfillment?.carrierShippingMethodCode ||
        (isPurchaseOrderRequired && !ds?.customerPoNumber?.trim()),
    ),
);

export const selectDropShipSiteId = createSelector(
  selectCartFeature,
  (cart) => cart?.cart?.selectedDropShipSiteId,
);

export const selectAllCartCouponCodes = createSelector(
  selectCartEntity,
  (cart) => cart?.couponCodes || [],
);

export const selectCurrentCartMaterialNumbers = createSelector(
  selectCartMaterialEntities,
  (entities) =>
    entities ? Object.keys(entities).filter((x) => !entities[x].isDeleted) : [],
);

export const selectCartMaterial = (materialNumber: string) =>
  createSelector(
    selectCartMaterialEntities,
    (cartMaterialEntities): CartMaterialState | undefined =>
      cartMaterialEntities[materialNumber],
  );

export const selectRouteDate = createSelector(
  selectCartEntity,
  (cart) => cart?.truckFulfillment?.routeDate,
);

export const selectCustomerArrivalDate = createSelector(
  selectCartEntity,
  (cart) => cart?.truckFulfillment?.customerArrivalDate,
);

export const selectPoNumber = createSelector(selectCartEntity, (cart) =>
  cart ? cart.customerPoNumber : undefined,
);

export const selectIsPoNumberInvalid = createSelector(
  selectPoNumber,
  selectIsPurchaseOrderRequired,
  (poNumber: string, isPoRequired: boolean) => {
    const hasValidPoNumber = !!poNumber && !!poNumber.trim();
    return isPoRequired ? !hasValidPoNumber : false;
  },
);

export const selectQuantityByUom = (materialNumber: string, uom: string) =>
  createSelector(selectCartMaterial(materialNumber), (cartMaterial) =>
    !!cartMaterial && !cartMaterial.isDeleted
      ? getQuantityFromCartMaterial(cartMaterial, uom)
      : 0,
  );

export const selectTotalQuantity = createSelector(
  selectAllOrderableCartMaterials,
  (cartMaterials) =>
    cartMaterials.reduce(
      (totalQuantity, cartMaterial) =>
        getTotalQuantity(totalQuantity, cartMaterial),
      0,
    ),
);

export const selectLineCount = createSelector(
  selectAllOrderableCartMaterials,
  (cartMaterials) =>
    cartMaterials.reduce((totalLineCount, cartMaterial) => {
      return getCartLineCount(totalLineCount, cartMaterial);
    }, 0),
);

export const selectCartCounts = createSelector(
  selectLineCount,
  selectTotalQuantity,
  (cartLineCount, totalQuantity): CartCounts => {
    return {
      cartLineCount,
      totalQuantity,
    };
  },
);

export const selectCartCountsForDropShip = createSelector(
  selectAllOrderableCartMaterials,
  selectDropShipOrders,
  (cartMaterials, splitOrders) =>
    splitOrders?.map((data: SplitOrder) => {
      const dropShipMaterials = cartMaterials.filter(
        (material: CartMaterialState) =>
          data.materialNumbers.includes(material.materialNumber),
      );
      const cartLineCount = dropShipMaterials.reduce(
        (totalQuantity, cartMaterial) =>
          getCartLineCount(totalQuantity, cartMaterial),
        0,
      );
      const totalLineQuantity = dropShipMaterials.reduce(
        (totalLineCount, cartMaterial) => {
          return getTotalQuantity(totalLineCount, cartMaterial);
        },
        0,
      );
      return {
        cartLineCount: cartLineCount,
        totalQuantity: totalLineQuantity,
        shipmentId: data?.carrierFulfillment?.shipmentId,
      };
    }),
);

export const selectCartAvailability = createSelector(
  selectAllMaterialAvailabilityRecords,
  selectCurrentCartMaterialNumbers,
  (
    availabilityRecordStates,
    materialNumbers,
  ): MaterialAvailabilityRecordState[] =>
    materialNumbers
      .map((materialNumber) => availabilityRecordStates[materialNumber])
      .filter((avail) => !!avail),
);

export const selectCartProductInfo = createSelector(
  selectAllMaterialInfoRecordStates,
  selectCurrentCartMaterialNumbers,
  (infoRecordStates, materialNumbers): MaterialInfoRecordState[] =>
    materialNumbers
      .map((materialNumber) => infoRecordStates[materialNumber])
      .filter((info) => !!info),
);

export const selectCartInventory = createSelector(
  selectInventoryAvailabilityRecordStates,
  selectCurrentCartMaterialNumbers,
  (
    inventoryRecordStates,
    materialNumbers,
  ): InventoryAvailabilityRecordState[] =>
    materialNumbers
      .map((materialNumber) => inventoryRecordStates[materialNumber])
      .filter((inventory) => !!inventory),
);

export const selectAnalyticsPreviousCartEntity = createSelector(
  selectCartFeature,
  (state) => state.analyticsPreviousCart,
);

export const selectIsDoneUpdating = createSelector(
  selectCartFeature,
  (state) =>
    state.outstandingUpdateRequests === 0 &&
    state.queuedMaterialNumbers.length === 0,
);

export const selectHasPendingFulfillmentChange = createSelector(
  selectCartFeature,
  (state) => state.hasPendingFulfillmentChange,
);

export const selectFocusedMaterial = createSelector(
  selectCartFeature,
  (state) => state.focusedMaterial,
);

export const selectStoreFulfillment = createSelector(
  selectCartFeature,
  (state): StoreFulfillment | undefined => state?.cart?.storeFulfillment,
);

export const selectExpressDeliveryDateString = createSelector(
  selectStoreFulfillment,
  (storeFulfillment): string | undefined =>
    storeFulfillment?.deliveryWindowStartTimestamp?.slice(0, 10),
);

export const selectPickupDateString = createSelector(
  selectStoreFulfillment,
  (storeFulfillment): string | undefined =>
    storeFulfillment?.requestedPickupTimestamp?.slice(0, 10),
);

export const selectFulfillmentType = createSelector(
  selectCartFeature,
  (state): FulfillmentType =>
    state?.cart?.fulfillmentType ?? FulfillmentType.NONE,
);

export const selectFulfillmentState = createSelector(
  selectFulfillmentType,
  selectCartFeature,
  (fulfillmentType, state): FulfillmentStateData => {
    return {
      fulfillmentType,
      hasPendingFulfillmentChange: state.hasPendingFulfillmentChange,
    };
  },
);

export const selectFulfillmentChangesHaveCompleted = createSelector(
  selectFulfillmentState,
  (fulfillmentState) => !fulfillmentState.hasPendingFulfillmentChange,
);

export const selectFulfillmentSelectionNotCompleted = createSelector(
  selectCartEntity,
  selectCurrentSystem,
  (cart, currentSystem): boolean => {
    const fulfillmentType = cart?.fulfillmentType;
    const truckFulfillment = cart?.truckFulfillment;
    const storeFulfillment = cart?.storeFulfillment;
    return (
      FulfillmentType.NONE === fulfillmentType ||
      (FulfillmentType.EXPRESS === fulfillmentType &&
        !storeFulfillment?.expressRouteId) ||
      (FulfillmentType.PICKUP === fulfillmentType &&
        !storeFulfillment?.storePlantId) ||
      (FulfillmentType.TRUCK === fulfillmentType &&
        (CurrentSystem.Retalix === currentSystem
          ? !truckFulfillment?.routeDate
          : !truckFulfillment?.customerArrivalDate))
    );
  },
);

export const selectCartMaterialQuantities = createSelector(
  selectCartEntity,
  (cartEntityState): MaterialQuantityDetail[] => {
    return buildMaterialQuantityDetails(cartEntityState);
  },
);

export const selectPreviousCartMaterialQuantities = createSelector(
  selectCartFeature,
  (cartState): MaterialQuantityDetail[] => {
    return buildMaterialQuantityDetails(cartState?.analyticsPreviousCart);
  },
);

export function getTotalQuantity(
  totalQuantity: number,
  cartMaterial: CartMaterialState,
) {
  return (
    totalQuantity +
    Object.values(cartMaterial.lines.entities).reduce(
      (totalLines, line) => totalLines + line.quantity,
      0,
    )
  );
}

export function getCartLineCount(
  totalLineCount: number,
  cartMaterial: CartMaterialState,
) {
  return (
    totalLineCount + filterNonEmptyCartLines(cartMaterial.lines.entities).length
  );
}

export function isMaterialOrderable(
  materialNumber: string,
  infos: Dictionary<MaterialInfoRecordState>,
  availabilities: Dictionary<MaterialAvailabilityRecordState>,
): boolean {
  const info = infos[materialNumber];
  const availability = availabilities[materialNumber];
  return (
    info?.status === MaterialInfoRecordStatus.Success &&
    !!availability?.record?.isOrderable
  );
}

function buildMaterialQuantityDetails(
  cartEntityState: CartEntityState,
): MaterialQuantityDetail[] {
  let index = 0;
  return Object.values(cartEntityState?.materials?.entities || []).map(
    (cartMaterial) => {
      return {
        materialNumber: cartMaterial.materialNumber,
        lines: Object.values(cartMaterial.lines.entities).map((cartLine) => {
          return {
            index: ++index,
            uom: cartLine.uom,
            quantity: cartLine.quantity,
          };
        }),
        isDeleted: cartMaterial.isDeleted,
      };
    },
  );
}
