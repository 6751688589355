import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { NaooAnalyticsManager } from '../analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../analytics/analytics-event-info';
import { LocalizationService } from '../services/translation/localization.service';
import { Language } from 'src/app/core/services/session/models/session-record';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

interface LanguageType {
  display: string;
  lang: Language;
}

@Component({
  selector: 'naoo-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatMenuTrigger,
    MatIcon,
    NgClass,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
})
export class LanguageMenuComponent {
  @Input() isDisabled: boolean;

  @ViewChild(MatMenuTrigger) languageMenuTrigger: MatMenuTrigger;

  protected readonly languages: LanguageType[] = [
    {
      display: 'English',
      lang: Language.en,
    },
    {
      display: 'Français',
      lang: Language.fr,
    },
    {
      display: 'Español',
      lang: Language.es,
    },
  ];

  constructor(
    private readonly localizationService: LocalizationService,
    private readonly analytics: NaooAnalyticsManager,
  ) {}

  toggleMenu(): void {
    this.languageMenuTrigger.openMenu();
  }

  toggleLanguage(event: MatButtonToggleChange): void {
    this.setLanguage(event.value);
  }

  setLanguage(language: Language): void {
    const eventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'general',
      label: 'change_language',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
    this.localizationService.use(language);
  }

  getSelectedLanguageDisplayName(): string {
    return this.languages.find(
      (language) => language.lang === this.localizationService.currentLanguage,
    )?.display;
  }
}
