import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  CustomerSelectOptions,
  CustomerUnit,
  MultiCustomerSelectComponent,
} from '../../../../multi-customer-select/multi-customer-select.component';
import { naooAnimations } from '../../../../animations/animations';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomGuideOverwriteWarning } from './copy-guide-select-customer-warning-model';
import { SessionFacade } from '../../../../../core/store/session/session.facade';
import { AsyncPipe, NgClass } from '@angular/common';
import { WarningAlertComponent } from '../../../../warning-alert/warning-alert.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-copy-guide-select-customer',
  templateUrl: './copy-guide-select-customer.component.html',
  animations: [naooAnimations.displayWarning],
  styleUrls: ['./copy-guide-select-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    WarningAlertComponent,
    MultiCustomerSelectComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class CopyGuideSelectCustomerComponent {
  @Output() cancelCopy = new EventEmitter<void>();
  @Output() beginCopy = new EventEmitter<CustomerUnit[]>();

  protected warningAnimationState: string;
  protected showWarning = false;
  protected isWarningDismissed = false;
  protected enableCopyCustomGuide = false;
  protected readonly copyWarning = new CustomGuideOverwriteWarning();
  protected readonly copyCustomSelectOption: CustomerSelectOptions = {
    cardContainerHeight: '236px',
    cardContainerHeightMobile: 'auto',
    rowHeight: '65px',
    overflowY: 'hidden',
    cols: 2,
    showTitle: false,
  };
  protected customerUnits$: Observable<CustomerUnit[]> = this.sessionFacade
    .getLoadedNonActiveCustomers()
    .pipe(
      map((customers) => {
        return customers.map((customer) => {
          return {
            customerName: customer.name,
            customerId: customer.customerDisplayId,
            compositeId: customer.naooCustomerId,
            customerAddress: customer.address.line1,
            initialSelect: false,
          };
        });
      }),
    );

  private readonly ANIMATE_OUT = 'animateOutFromBottom';
  private readonly ANIMATE_IN = 'animateInFromTop';

  private selectedCustomerUnits: CustomerUnit[] = [];

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly sessionFacade: SessionFacade,
  ) {}

  copyToSelectedUnits(): void {
    this.beginCopy.emit(this.selectedCustomerUnits);
  }

  selectCustomer(selectedCustomerUnits: CustomerUnit[]): void {
    this.selectedCustomerUnits = selectedCustomerUnits;
    this.enableCopyCustomGuide =
      selectedCustomerUnits && selectedCustomerUnits.length > 0;

    if (this.enableCopyCustomGuide && !this.isWarningDismissed) {
      this.showWarning = true;
      this.warningAnimationState = this.ANIMATE_IN;
    }
    this.changeDetector.markForCheck();
  }

  gotIt(): void {
    this.isWarningDismissed = true;
    this.warningAnimationState = this.ANIMATE_OUT;
    this.changeDetector.markForCheck();
  }

  animationComplete(): void {
    this.showWarning = this.warningAnimationState === this.ANIMATE_IN;
    this.changeDetector.markForCheck();
  }
}
