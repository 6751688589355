<button
  class="top-level-button"
  [ngClass]="{ disabled: isOffline }"
  attr.aria-label="{{
    isString(menuModel?.ariaLabel)
      ? (menuModel?.ariaLabel | translate)
      : (menuModel?.ariaLabel | stringdefaulter)
  }}"
  [matMenuTriggerFor]="menu"
  [disabled]="isOffline"
>
  @if (menuModel?.icon) {
    <mat-icon class="menu-icon" [svgIcon]="menuModel.icon"></mat-icon>
  }

  @if (isString(menuModel?.title)) {
    {{ menuModel?.title | translate }}
  } @else {
    {{ menuModel?.title | stringdefaulter }}
  }

  <mat-icon
    [ngClass]="{ 'rotate-arrow': trigger?.menuOpen }"
    class="dropdown-arrow-icon"
    svgIcon="arrow-v2"
  ></mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="after">
  @for (menuElement of limitedMenuElements; track menuElement.route) {
    @if (menuElement.menuElements?.length) {
      <button
        mat-menu-item
        (click)="menuClicked($event, menuComponent)"
        (keydown.enter)="menuClicked($event, menuComponent)"
        (keydown.space)="menuClicked($event, menuComponent)"
        class="menu-element"
      >
        <naoo-menu-element
          #menuComponent
          [menuElement]="menuElement"
          [listSizeLimit]="menuModel.maxDisplayed"
          class="menu-element"
        ></naoo-menu-element>
      </button>
    } @else {
      <button
        (click)="navigateToRoute(menuElement)"
        mat-menu-item
        class="menu-element"
      >
        <naoo-menu-element
          [menuElement]="menuElement"
          [listSizeLimit]="menuModel.maxDisplayed"
          class="menu-element"
        ></naoo-menu-element>
      </button>
    }
  }
</mat-menu>
