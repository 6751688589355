<div class="delivery-date-details">
  <div class="delivery-date-label">
    {{ 'DELIVERY_DATE.DATE_PICKER_DATE_DETAILS' | translate }}
  </div>
  <div class="delivery-info">
    <div class="labels">
      <div class="deliver-on-label">
        {{ 'DELIVERY_DATE.DATE_PICKER_DATE_ON' | translate }}
      </div>
      @if (shouldDisplayCutoffDeadline) {
        <div class="deadline-label">
          {{ 'DELIVERY_DATE_PICKER.ORDER_DEADLINE' | translate }}
        </div>
      }
    </div>
    <div class="dates">
      <div class="delivery-date">
        {{
          (shouldUseCustomerArrivalDate
            ? calculatedAvailableRouteDate.customerArrivalDate
            : calculatedAvailableRouteDate.routeDate
          ) | naooDate: 'shortYearDate' : '+0000'
        }}
      </div>
      @if (shouldDisplayCutoffDeadline) {
        <div class="order-deadline">
          {{
            calculatedAvailableRouteDate.cutoffDateTime
              | naooDate
                : 'shortYearDateTime'
                : customerCutoffTimeZoneAbbreviation
          }}
          {{ localizedCustomerCutoffTimeZoneAbbreviation }}
        </div>
      }
    </div>
  </div>
  <div class="disclaimer" [ngClass]="{ 'extra-padding': !isJustBrowsing }">
    {{ 'DELIVERY_DATE_PICKER.DISCLAIMER' | translate }}
  </div>

  <div class="desktop-footer">
    <button
      #desktopSaveButton
      class="desktop-save-date-button"
      (click)="onSaveClicked()"
    >
      {{
        (isJustBrowsing ? 'DELIVERY_DATE_PICKER.START_MY_ORDER' : 'SHARED.SAVE')
          | translate
      }}
    </button>
    @if (isJustBrowsing) {
      <button
        class="desktop-just-browsing-button"
        (click)="onJustBrowsingClicked()"
      >
        {{ 'DELIVERY_DATE_PICKER.JUST_BROWSING' | translate }}
      </button>
    }
  </div>
</div>
