import {
  ApplicationRef,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  Provider,
} from '@angular/core';
import { environment } from './environments/environment';
import {
  bootstrapApplication,
  BrowserModule,
  enableDebugTools,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';

// Needed for gestures
import 'hammerjs';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { Angulartics2GoogleTagManager, Angulartics2Module } from 'angulartics2';
import { NaooErrorHandler } from './app/shared/error-handler/NaooErrorHandler';
import { NaooErrorUtils } from './app/shared/error-handler/NaooErrorUtils';
import { NaooHttpClient } from './app/shared/http-client/naoo-http-client';
import {
  RouterState,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './app/core/store/router/router-state-serializer';
import { httpInterceptorProviders } from './app/shared/interceptors';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NaooHammerGestureConfig } from './app/hammer-gesture-config';
import { SCROLL_CONTAINER_PROVIDER } from './app/shared/services/scrollable-content/scrollable-content.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { InlineTranslateLoader } from './app/shared/inline-translate-loader/inline-translate-loader';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AppComponent } from './app/app.component';
import {
  ErrorStateMatcher,
  MatNativeDateModule,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeFrCa from '@angular/common/locales/fr-CA';
import localeEnCa from '@angular/common/locales/en-CA';
import localeEsUs from '@angular/common/locales/es-US';
import { VirtualScrollerModule } from './app/vendor/ngx-virtual-scroller/virtual-scroller';
import {
  ORDER_CONFIRMATION_POLLING_DELAY_MILLIS,
  ORDER_CONFIRMATION_POLLING_INTERVAL_MILLIS,
} from './app/core/injection-tokens';
import { AppStoreModule } from './app/core/store/app-store.module';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router';
import { routes } from './app/app.routes';

registerLocaleData(localeFrCa);
registerLocaleData(localeEnCa);
registerLocaleData(localeEsUs);

const providers: Provider[] = [
  TranslateService,
  Angulartics2GoogleTagManager,
  { provide: ErrorHandler, useClass: NaooErrorHandler },
  { provide: NaooErrorUtils, useClass: NaooErrorUtils },
  { provide: HttpClient, useClass: NaooHttpClient },
  { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
  { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  httpInterceptorProviders,
  {
    provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'legacy', // This changed in Material 7 so we need to pin it to the old style
    },
  },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic',
    },
  },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: NaooHammerGestureConfig,
  },
  {
    provide: ORDER_CONFIRMATION_POLLING_DELAY_MILLIS,
    useValue: 5000,
  },
  {
    provide: ORDER_CONFIRMATION_POLLING_INTERVAL_MILLIS,
    useValue: 2000,
  },
  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  { provide: Window, useValue: window },
  SCROLL_CONTAINER_PROVIDER,
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      AppStoreModule,
      VirtualScrollerModule,
      BrowserModule,
      MatNativeDateModule,
      MatMomentDateModule,
      HammerModule,
      MatIconModule,
      StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
      Angulartics2Module.forRoot(),
      TranslateModule.forRoot({
        loader: { provide: TranslateLoader, useClass: InlineTranslateLoader },
      }),
      ScrollingModule,
    ),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()), // withJsonpSupport needed for GoogleMaps
    ...providers,
    provideAnimations(),
  ],
})
  .then((moduleRef) => {
    /**
     * Enable the change detection profiler when we're developing
     * run "ng.profiler.timeChangeDetection();" in console
     * https://github.com/angular/angular/blob/master/docs/TOOLS.md#performance
     */
    if (!environment.production) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const appComponent = applicationRef.components[0];
      enableDebugTools(appComponent);
    }
  })
  .catch((err) => console.log(err));
