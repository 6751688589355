import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  GoogleMapsNaooService,
  StoreMapMarkers,
} from '../service/google-maps.service';
import { StoreRecord } from '../../../core/services/store/model/store-record';
import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import { GoogleAutocompleteNaooComponent } from './google-autocomplete/google-autocomplete.component';
import { StoreDisplayComponent } from '../../store-display/store-display.component';
import { GoogleMapsNaooComponent } from './google-maps/google-maps.component';
import LatLngLiteral = google.maps.LatLngLiteral;
import MapOptions = google.maps.MapOptions;

@Component({
  selector: 'naoo-google-maps-container',
  templateUrl: './google-maps-container.component.html',
  styleUrls: ['./google-maps-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GoogleAutocompleteNaooComponent,
    NgClass,
    StoreDisplayComponent,
    GoogleMapsNaooComponent,
    AsyncPipe,
  ],
})
export class GoogleMapsNaooContainerComponent implements OnInit {
  @Input() selectedStorePlantId: string;
  @Input() customerTimeZone: string;
  @Input() isMobileWidth: boolean;
  @Output() selectedStorePlantIdEmitter = new EventEmitter<string>();

  protected readonly hasLoaded$: Observable<boolean> =
    this.googleMapsService.hasLoaded();
  protected readonly userLatLong$: Observable<LatLngLiteral> =
    this.googleMapsService.getUserLatLongLiteral();
  protected readonly storeMapMarkers$: Observable<StoreMapMarkers> =
    this.googleMapsService.getStoreMapMarkers();
  protected readonly mapOptions: MapOptions =
    this.googleMapsService.getMapOptions();

  selectedAddressLatLong: LatLngLiteral;
  visibleStoreRecords: StoreRecord[] = [];

  constructor(
    private readonly googleMapsService: GoogleMapsNaooService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  ngOnInit(): void {
    this.googleMapsService.initialize();
  }

  selectStorePlantId(storePlantId: string): void {
    this.selectedStorePlantIdEmitter.emit(storePlantId);
    this.focusIndexPosition(0);
  }

  selectLatLong(latLong: LatLngLiteral): void {
    this.selectedAddressLatLong = latLong;
  }

  updateVisibleStores(storeRecords: StoreRecord[]): void {
    this.visibleStoreRecords = storeRecords;
    this.changeDetectorRef.markForCheck();
  }

  focusIndexPosition(indexPosition: number): void {
    const allInputs = this.queryForAllInputs();
    const element = allInputs[indexPosition];
    if (element) {
      element.focus();
    }
  }

  private queryForAllInputs(): HTMLInputElement[] {
    return Array.from(
      this._document.getElementsByClassName('store-select'),
    ).filter((x) => {
      return x.getBoundingClientRect().height > 0;
    }) as HTMLInputElement[];
  }
}
