import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImportGuideStep } from '../import-guide-step.enum';
import { ImportGuideValidationService } from '../../shared/services/import-guide-validation/import-guide-validation.service';
import { first } from 'rxjs/operators';
import { ImportGuideValidationData } from '../../shared/models/import-guide/import-guide-validation-data';
import { ImportGuideRow } from '../../shared/models/import-guide/import-guide-row';
import { ImportGuideValidationResultType } from '../../shared/models/import-guide/import-guide-validation-result-type';
import { Router } from '@angular/router';
import { ImportGuideCategorizedProducts } from '../../shared/models/import-guide/import-guide-categorized-products';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { ModalHeaderComponent } from '../../shared/modal-header/modal-header.component';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import {
  CustomGuideCategoryRequest,
  CustomGuideMaterialRequest,
} from '../../core/services/custom-guide/model/custom-guide-request';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ImportGuideCreateCustomGuideComponent } from '../steps/import-guide-create-custom-guide/import-guide-create-custom-guide.component';
import { ImportFileComponent } from '../../shared/modals/import-modals/import-file/import-file.component';
import { RowErrorComponent } from '../../shared/modals/import-modals/error-modals/row-error/row-error.component';
import { ImportGuideErrorTableComponent } from '../steps/row-error/naoo-import-guide-error-table/import-guide-error-table.component';
import { FileErrorComponent } from '../../shared/modals/import-modals/error-modals/file-error/file-error.component';
import { CompleteModalComponent } from '../../shared/modals/complete-modal/complete-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-import-guide-modal',
  templateUrl: './import-guide-modal.component.html',
  styleUrls: ['./import-guide-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalHeaderComponent,
    MatProgressBar,
    ImportGuideCreateCustomGuideComponent,
    ImportFileComponent,
    RowErrorComponent,
    ImportGuideErrorTableComponent,
    FileErrorComponent,
    CompleteModalComponent,
    TranslateModule,
  ],
})
export class ImportGuideModalComponent {
  protected readonly completeImportImagePath =
    'assets/images/import-guide-complete.svg';
  protected readonly fileRequirements: string[] = [
    'IMPORT_GUIDE.FILE_TYPE_REQUIREMENT',
    'IMPORT_GUIDE.COLUMN_FORMAT_REQUIREMENT',
    'IMPORT_GUIDE.CAT_NAME_OPTIONAL_REQUIREMENT',
  ];
  protected fileError: string;
  protected fileName: string;

  completeImportSubtitle: string;
  categoriesCount: number;
  importGuideCurrentStep: ImportGuideStep | null = ImportGuideStep.CreateGuide;

  customGuideName = '';
  showCloseButton = true;
  validationResultData: ImportGuideValidationData;

  validImportGuideRows: ImportGuideRow[] = [];
  invalidImportGuideRows: ImportGuideRow[] = [];
  customGuideId: string;

  private readonly analyticsCategory = 'Import Custom Guide';

  @ViewChild(ModalHeaderComponent, { static: true })
  modalHeader: ModalHeaderComponent;

  constructor(
    private dialogRef: MatDialogRef<ImportGuideModalComponent>,
    private importGuideValidationService: ImportGuideValidationService,
    private customGuideFacade: CustomGuideFacade,
    private router: Router,
    private defaultDialogService: DefaultDialogService,
    private analyticsManager: NaooAnalyticsManager,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  get importGuideStep(): typeof ImportGuideStep {
    return ImportGuideStep;
  }

  get totalRowCount(): number {
    return (
      this.validImportGuideRows.length + this.invalidImportGuideRows.length
    );
  }

  close(userCanceled?: boolean): void {
    this.dialogRef.close(!!this.customGuideId);
    if (userCanceled) {
      this.trackCloseModal();
    }
  }

  createGuideStepNext(customGuideName: string): void {
    this.customGuideName = customGuideName;
    this.changeDetectorRef.markForCheck();
    this.setModalState(ImportGuideStep.ImportFile);
  }

  importFileStepNext(file: Blob): void {
    this.fileName = (file as File).name;
    this.changeDetectorRef.markForCheck();
    this.trackSelectedFileType(this.fileName);
    this.setModalState(ImportGuideStep.Loading);
    this.importGuideValidationService
      .validateFile(file)
      .pipe(first())
      .subscribe(
        (result) => {
          const nextStep = this.determineStatePostValidation(result.type);
          this.validationResultData = result;
          this.changeDetectorRef.markForCheck();
          this.segregateImportRows(result.type);
          this.setModalState(nextStep);
          this.trackValidationError(nextStep);
          if (result.type === ImportGuideValidationResultType.NoErrors) {
            this.importCustomGuide();
          }
        },
        () => this.importGuideError(),
      );
  }

  rowErrorStepNext(): void {
    this.setModalState(ImportGuideStep.Loading);
    this.importCustomGuide();
  }

  startOver(): void {
    this.setModalState(ImportGuideStep.CreateGuide);
  }

  goToGuide(): void {
    this.router.navigate(['guides', 'custom-guide', this.customGuideId]);
    this.close(false);
  }

  importGuideError(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.defaultDialogService.defaultErrorModal('');
    });
    this.close(false);
  }

  private importCustomGuide(): void {
    const categorizedProductList = this.createCategorizedProductsList();
    this.customGuideFacade
      .importCustomGuide(this.customGuideName, categorizedProductList)
      .pipe(first())
      .subscribe(
        (customGuide) => {
          this.customGuideId = customGuide.id;
          this.categoriesCount = this.calculateCategories(
            categorizedProductList,
          );
          this.completeImportSubtitle = this.getSubtitleCopy(
            this.validImportGuideRows.length,
            this.categoriesCount,
          );
          this.changeDetectorRef.markForCheck();
          this.setModalState(ImportGuideStep.Complete);
          this.sendImportCompleteAnalytic();
        },
        () => this.importGuideError(),
      );
  }

  private setModalState(importGuideStep: ImportGuideStep | null): void {
    if (importGuideStep === ImportGuideStep.Loading) {
      this.showCloseButton = false;
    } else {
      this.showCloseButton = true;
      setTimeout(() => {
        this.modalHeader.focusCloseButton();
        this.changeDetectorRef.markForCheck();
      });
    }
    this.importGuideCurrentStep = importGuideStep;
    this.changeDetectorRef.markForCheck();
  }

  private determineStatePostValidation(
    type: ImportGuideValidationResultType,
  ): ImportGuideStep | null {
    switch (type) {
      case ImportGuideValidationResultType.RowErrors:
        return ImportGuideStep.RowError;
      case ImportGuideValidationResultType.BlankFile:
        this.fileError = 'IMPORT_GUIDE.FILE_ERROR.BLANK_FILE';
        this.changeDetectorRef.markForCheck();
        return ImportGuideStep.FileError;
      case ImportGuideValidationResultType.InvalidFileFormat:
        this.fileError = 'IMPORT_GUIDE.FILE_ERROR.INVALID_FORMAT_ERROR';
        this.changeDetectorRef.markForCheck();
        return ImportGuideStep.FileError;
      case ImportGuideValidationResultType.NoErrors:
        return ImportGuideStep.Loading;
      default:
        return null;
    }
  }

  private segregateImportRows(type: ImportGuideValidationResultType): void {
    if (
      type === ImportGuideValidationResultType.BlankFile ||
      type === ImportGuideValidationResultType.InvalidFileFormat
    ) {
      return;
    }

    this.validImportGuideRows = [];
    this.invalidImportGuideRows = [];
    this.validationResultData.data.forEach((row) => {
      if (row.error === undefined) {
        this.validImportGuideRows.push(row);
      } else {
        this.invalidImportGuideRows.push(row);
      }
    });
    this.changeDetectorRef.markForCheck();
    this.sortInvalidImportGuideRows();
  }

  private sortInvalidImportGuideRows(): void {
    if (this.invalidImportGuideRows.length > 1) {
      this.invalidImportGuideRows.sort((row1, row2) => {
        return row1.rowNumber - row2.rowNumber;
      });
      this.changeDetectorRef.markForCheck();
    }
  }

  private getSubtitleCopy(validLines: number, categories: number): string {
    if (categories === 1 && validLines === 1) {
      return 'IMPORT_GUIDE.COMPLETE.IMPORT_LINE_AND_CATEGORY';
    } else if (categories === 1 && validLines !== 1) {
      return 'IMPORT_GUIDE.COMPLETE.IMPORT_LINES_AND_CATEGORY';
    } else if (categories !== 1 && validLines === 1) {
      return 'IMPORT_GUIDE.COMPLETE.IMPORT_LINE_AND_CATEGORIES';
    } else {
      return 'IMPORT_GUIDE.COMPLETE.IMPORT_LINES_AND_CATEGORIES';
    }
  }

  private createCategorizedProductsList(): CustomGuideCategoryRequest[] {
    const categorizedProducts: ImportGuideCategorizedProducts[] = [];
    this.validImportGuideRows.forEach((row) => {
      const categorizedProductIndex = categorizedProducts.findIndex(
        (categorizedProduct) => {
          return categorizedProduct.categoryName === row.category;
        },
      );

      if (categorizedProductIndex === -1) {
        const categorizedProduct = new ImportGuideCategorizedProducts(
          row.category,
          row.itemId,
        );
        categorizedProducts.push(categorizedProduct);
      } else {
        if (row.itemId) {
          categorizedProducts[categorizedProductIndex].addProduct(row.itemId);
        }
      }
    });
    return categorizedProducts.map((categorizedProduct) => {
      return {
        name: categorizedProduct.categoryName,
        materials: categorizedProduct.productIds.map(
          (materialNumber) =>
            <CustomGuideMaterialRequest>{ materialNumber, parLines: [] },
        ),
      };
    });
  }

  private calculateCategories(
    categorizedProductList: CustomGuideCategoryRequest[],
  ): number {
    if (
      categorizedProductList.find(
        (categorizedProduct) => categorizedProduct.name === '',
      ) === undefined
    ) {
      return categorizedProductList.length;
    } else {
      return categorizedProductList.length - 1;
    }
  }

  private trackSelectedFileType(fileName: string): void {
    const fileNameParts = fileName.split('.');
    const fileType = fileNameParts.length > 1 ? fileNameParts.pop() : 'Unknown';

    const eventInfo: AnalyticsEventInfo = {
      action: 'Upload',
      category: this.analyticsCategory,
      label: fileType,
    };

    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  trackTemplateDownload(type: string): void {
    const templateLabel = type === 'csv' ? 'CSV Template' : 'Excel Template';
    const eventInfo: AnalyticsEventInfo = {
      action: 'Download',
      category: this.analyticsCategory,
      label: `${this.importGuideCurrentStep} ${templateLabel}`,
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private trackValidationError(step: ImportGuideStep | null): void {
    if (
      step !== ImportGuideStep.RowError &&
      step !== ImportGuideStep.FileError
    ) {
      return;
    }

    const eventInfo: AnalyticsEventInfo = {
      action: 'Error',
      category: this.analyticsCategory,
      label: step === ImportGuideStep.RowError ? 'Row Level' : 'File Level',
    };

    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private sendImportCompleteAnalytic(): void {
    const eventInfo: AnalyticsEventInfo = {
      action: 'Complete',
      category: 'Import Custom Guide',
      label: 'Import Custom Guide Complete',
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private trackCloseModal(): void {
    const eventInfo: AnalyticsEventInfo = {
      action: 'Cancel',
      category: this.analyticsCategory,
      label: `${this.importGuideCurrentStep} X Button`,
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }
}
