import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  ReOrderMaterial,
  ReOrderValidationResult,
} from '../../../re-order-validation-result';
import { DOCUMENT, NgClass, NgTemplateOutlet } from '@angular/common';
import { NaooAnalyticsManager } from '../../../../shared/analytics/NaooAnalyticsManager';
import { Subject } from 'rxjs';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { LocalizedUtilities } from '../../../../shared/utilities/localized-utilities';
import { MatIcon } from '@angular/material/icon';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-unavailable-products',
  templateUrl: './unavailable-products.component.html',
  styleUrls: ['./unavailable-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    NgTemplateOutlet,
    NgClass,
    NaooStringDefaulterPipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class UnavailableProductsComponent implements OnInit, OnDestroy {
  @Input() validationResult: ReOrderValidationResult;
  @Output() addItemsToCart = new EventEmitter<void>();
  @Output() cancelAddingItemsToCart = new EventEmitter<void>();

  protected orderableCount: number;
  protected nonOrderableItems: ReOrderMaterial[][];
  protected expandedRowId: string;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly localizationService: LocalizationService,
    private readonly analyticsManager: NaooAnalyticsManager,
    private readonly renderer2: Renderer2,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.orderableCount = Object.keys(this.validationResult.orderable).length;
    this.nonOrderableItems = Object.keys(
      this.validationResult.nonOrderable,
    ).map(
      (materialNumber) => this.validationResult.nonOrderable[materialNumber],
    );
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSelect(rowId: string): void {
    this.expandedRowId = rowId === this.expandedRowId ? undefined : rowId;
    this.changeDetectorRef.markForCheck();
  }

  rowId(orderItems: ReOrderMaterial[]): string {
    return orderItems.length > 0 ? orderItems[0].materialNumber : '';
  }

  onCopyToClipboard(): void {
    const selBox = this.renderer2.createElement('textarea');
    selBox.value = this.buildTextToCopy(
      this.nonOrderableItems.map((items) => items[0]),
    );
    this.renderer2.appendChild(this._document.body, selBox);
    selBox.select();
    this._document.execCommand('copy');
    this.renderer2.removeChild(this._document.body, selBox);

    this.trackClickEvent();
  }

  private buildTextToCopy(items: ReOrderMaterial[]): string {
    return items
      .map((item) => {
        const localizedDescription = LocalizedUtilities.getLocalizedStringValue(
          item.description,
          this.localizationService.currentLanguage,
        );
        return `${item.materialNumber},"${localizedDescription}"`;
      })
      .join('\n');
  }

  onAddItemsToCart(): void {
    this.addItemsToCart.emit();
  }

  onCancel(): void {
    this.cancelAddingItemsToCart.emit();
  }

  private trackClickEvent(): void {
    this.analyticsManager.trackAnalyticsEvent({
      action: 'Click',
      category: 'Reorder From History',
      label: 'Copy Items to Clipboard',
    });
  }
}
