import { Routes } from '@angular/router';
import { customerUnitSelectionGuard } from './shared/guards/customer-unit-selection.guard';
import { lockedToOrderGuideGuard } from './shared/guards/locked-to-order-guide.guard';
import { scheduledMaintenanceGuard } from './shared/guards/scheduled-maintenance.guard';
import { redirectGuard } from './shared/guards/redirect.guard';
import { cartLoadedGuard } from './shared/guards/cart-loaded-guard';
import { expressSchedulesValidGuard } from './shared/guards/express-schedules-valid.guard';
import { orderConfirmationResolver } from './order-confirmation/shared/order-confirmation-resolver';
import { punchOutGuard } from './shared/guards/punch-out.guard';
import { featureFlagEnabledGuard } from './shared/guards/feature-flag-enabled.guard';
import { FeatureFlag } from './core/services/session/models/session-record';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./home-page/home-page/home-page.component').then(
        (m) => m.HomePageComponent,
      ),
    canActivate: [
      customerUnitSelectionGuard,
      lockedToOrderGuideGuard,
      scheduledMaintenanceGuard,
    ],
  },
  {
    path: 'customer-unit-selection',
    loadComponent: () =>
      import(
        './customer-unit-selection/customer-unit-selection/customer-unit-selection.component'
      ).then((m) => m.CustomerUnitSelectionComponent),
    canActivate: [customerUnitSelectionGuard],
    canDeactivate: [redirectGuard],
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./categories/categories.routes').then((m) => m.categoryRoutes),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'collections',
    loadChildren: () =>
      import('./marketing-guide/marketing-guide.routes').then(
        (m) => m.marketingGuideRoutes,
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'mobile-hamburger-menu',
    canMatch: [featureFlagEnabledGuard(FeatureFlag.BAYMARD_NAVIGATION)],
    loadComponent: () =>
      import(
        './mobile-hamburger-menu/mobile-hamburger-menu-v2/mobile-hamburger-menu-v2.component'
      ).then((m) => m.MobileHamburgerMenuV2Component),
  },
  {
    path: 'mobile-hamburger-menu',
    loadComponent: () =>
      import(
        './mobile-hamburger-menu/mobile-hamburger-menu-v1/mobile-hamburger-menu-v1.component'
      ).then((m) => m.MobileHamburgerMenuV1Component),
  },
  {
    path: 'search',
    loadComponent: () =>
      import(
        './search/search-results-container/search-results-container.component'
      ).then((m) => m.SearchResultsContainerComponent),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.routes').then((m) => m.cartRoutes),
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      cartLoadedGuard,
    ],
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./product-details/product-details.routes').then(
        (m) => m.productDetailsRoutes,
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'my-id-card',
    loadComponent: () =>
      import('./my-id-card/my-id-card/my-id-card.component').then(
        (m) => m.MyIdCardComponent,
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'guides',
    loadChildren: () =>
      import('./guides/guides.routes').then((m) => m.guidesRoutes),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.routes').then((m) => m.ordersRoutes),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'cart-review',
    loadComponent: () =>
      import('./cart/cart-review/cart-review-container.component').then(
        (m) => m.CartReviewContainerComponent,
      ),
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      cartLoadedGuard,
      expressSchedulesValidGuard,
    ],
  },
  {
    path: 'order-confirmation',
    loadComponent: () =>
      import(
        './order-confirmation/order-confirmation-container.component'
      ).then((m) => m.OrderConfirmationContainerComponent),
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      expressSchedulesValidGuard,
    ],
    resolve: {
      orderConfirmation: orderConfirmationResolver,
    },
  },
  {
    path: 'preferences',
    loadChildren: () =>
      import('./account/preferences/preferences.routes').then(
        (m) => m.preferencesRoutes,
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'page-not-found',
    loadComponent: () =>
      import('./not-found/not-found/not-found.component').then(
        (m) => m.NotFoundComponent,
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'info-unavailable',
    loadComponent: () =>
      import(
        './info-unavailable/info-unavailable/info-unavailable.component'
      ).then((m) => m.InfoUnavailableComponent),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'scheduled-maintenance',
    loadComponent: () =>
      import(
        './scheduled-maintenance/scheduled-maintenance/scheduled-maintenance.component'
      ).then((m) => m.ScheduledMaintenanceComponent),
    canActivate: [customerUnitSelectionGuard],
  },
  {
    path: 'product-comparison',
    loadComponent: () =>
      import('./material-comparison/material-comparison.component').then(
        (m) => m.MaterialComparisonComponent,
      ),
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'offline-error',
    loadComponent: () =>
      import('./offline/offline-error.component').then(
        (m) => m.OfflineErrorComponent,
      ),
  },
  {
    path: 'punch-out',
    loadComponent: () =>
      import('./punch-out/punch-out/punch-out.component').then(
        (m) => m.PunchOutComponent,
      ),
    canActivate: [
      punchOutGuard,
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
    ],
  },
  {
    path: 'site',
    loadChildren: () => import('./site/site.routes').then((m) => m.siteRoutes),
    canActivate: [customerUnitSelectionGuard],
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];
