import { startWith, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NaooConstants } from './shared/NaooConstants';
import { environment } from '../environments/environment';
import { DOCUMENT, NgClass } from '@angular/common';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { AuthService } from './shared/services/auth/auth.service';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { FaviconService } from './shared/favicon/favicon.service';
import { SessionFacade } from './core/store/session/session.facade';
import { OfflineModeFacade } from './core/store/offline-mode/offline-mode.facade';
import {
  NaooIcon,
  NaooIconService,
} from './shared/services/images/naoo-icon.service';
import { RouteNavigationSpinnerService } from './shared/route-navigation-spinner/route-navigation-spinner.service';
import { CustomerBrand } from './core/services/session/models/session-record';
import { LocalizationService } from './shared/services/translation/localization.service';
import { LogRocketService } from './shared/services/logrocket/logrocket.service';
import { EcommerceAnalyticsFacade } from './core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { HeaderComponent } from './header/header/header.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { OfflineErrorComponent } from './offline/offline-error.component';
import { CommodityDocketComponent } from './shared/commodity-docket/commodity-docket.component';
import { CompareDocketOverlayComponent } from './material-comparison/compare-docket-overlay/compare-docket-overlay.component';
import { PreloadImagesComponent } from './shared/preload-images/preload-images.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'naoo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HeaderComponent,
    CdkScrollable,
    NgClass,
    RouterOutlet,
    OfflineErrorComponent,
    CommodityDocketComponent,
    CompareDocketOverlayComponent,
    PreloadImagesComponent,
    MatIcon,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  protected imagesToPreload: string[] = NaooConstants.PRE_LOAD_CACHED;
  protected iconsToPreload: NaooIcon[] = NaooConstants.SVG_PRE_LOAD_CACHED;
  protected customerBrand: CustomerBrand;
  isHeroPage = true;
  isFlushPage = true;
  isCenteredPage = true;
  isMcuPage = true;
  currentRouteSupportsOffline = true;
  isOffline = false;
  hideHeader = true;

  imposeWhiteBackground: boolean;

  private readonly destroyed$ = new Subject<void>();

  // eslint-disable-next-line max-params
  constructor(
    private readonly authService: AuthService,
    private readonly localizationService: LocalizationService,
    private readonly gtm: Angulartics2GoogleTagManager,
    private readonly renderer2: Renderer2,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly titleService: Title,
    private readonly faviconService: FaviconService,
    private readonly sessionFacade: SessionFacade,
    private readonly offlineFacade: OfflineModeFacade,
    private readonly routeNavigationSpinnerService: RouteNavigationSpinnerService,
    private readonly iconService: NaooIconService,
    private readonly logRocketService: LogRocketService,
    private readonly ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    private readonly _window: Window,
  ) {
    iconService.registerIcons(this.iconsToPreload);

    if (environment.usesWiremock && this.authService.isMissingToken()) {
      this.authService.processAuthError();
    }
  }

  ngOnInit(): void {
    this.logRocketService.initLogRocket();
    this.iconService.registerIcons(Object.values(NaooConstants.icons));
    this.routeNavigationSpinnerService.initialize();
    if (environment.hasGtm) {
      this.createAnalyticsTag();
      this.gtm.startTracking();
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = this.pathWithoutParams(event.url);

        const urlAfterRedirects = this.pathWithoutParams(
          event.urlAfterRedirects,
        );

        this.currentRouteSupportsOffline =
          !this.isOffline ||
          NaooConstants.offlineSupportedRoutes.some((routeRegex) =>
            url.match(routeRegex),
          );

        this.imposeWhiteBackground = NaooConstants.WHITE_BACKGROUND_ROUTES.some(
          (routeRegex) => url.match(routeRegex),
        );
        if (this.imposeWhiteBackground) {
          this.renderer2.addClass(this._document.body, 'alternate');
        } else {
          this.renderer2.removeClass(this._document.body, 'alternate');
        }

        this.hideHeader = NaooConstants.OMIT_HEADER_ROUTES.some((routeRegex) =>
          url.match(routeRegex),
        );
        this.isHeroPage = NaooConstants.HERO_PAGE_ROUTES.some((routeRegex) =>
          urlAfterRedirects.match(routeRegex),
        );
        this.isFlushPage = NaooConstants.FLUSH_PAGE_ROUTES.some((routeRegex) =>
          urlAfterRedirects.match(routeRegex),
        );
        this.isCenteredPage = NaooConstants.CENTERED_PAGE_ROUTES.some(
          (routeRegex) => urlAfterRedirects.match(routeRegex),
        );
        this.isMcuPage =
          NaooConstants.CUSTOMER_UNIT_SELECTION_PATH === urlAfterRedirects;

        this.changeDetectorRef.markForCheck();
      }
    });

    this.loadSessionInfo();

    this.initiateOfflineCheck();

    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        this.setPageTitle();
      });

    this.offlineFacade
      .getIsOffline()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isOffline) => {
        this.isOffline = isOffline;
        this.changeDetectorRef.markForCheck();
      });

    this._window.ketch(
      'on',
      'consent',
      (event?: { purposes?: { [key: string]: boolean } }): void =>
        this.ngZone.run(() =>
          this.ecommerceAnalyticsFacade.updateBehavioralAdvertising(
            event?.purposes?.['behavioral_advertising'] ?? false,
          ),
        ),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initiateOfflineCheck(): void {
    this.offlineFacade.startCheckingOffline();
  }

  private pathWithoutParams(path: string): string {
    path = this.pathWithoutMatrixParams(path);

    const index = path.indexOf('?');
    if (index > 0) {
      return path.substring(0, index);
    } else {
      return path;
    }
  }

  private pathWithoutMatrixParams(path: string): string {
    return path.replace(/;[^/]+/g, '');
  }

  private loadSessionInfo(): void {
    this.sessionFacade.loadSession();

    this.sessionFacade
      .getLoadedCustomerBrand()
      .pipe(takeUntil(this.destroyed$), startWith(CustomerBrand.Global))
      .subscribe((customerBrand) => {
        this.customerBrand = customerBrand;
        this.changeDetectorRef.markForCheck();
        this.faviconService.useFavicon(customerBrand);
        this.setPageTitle();
      });
  }

  private setPageTitle(): void {
    const title = this.localizationService.instant(
      `TITLE.${this.customerBrand}`,
      undefined,
      this.localizationService.currentLanguage,
    );
    this.titleService.setTitle(title);
  }

  /**
   * Let's get funky! We need to jump outside the typical Angular flow
   * a bit here since we're dynamically including a script tag for analytics.
   * We inject the renderer and generate a tag so we can use environment settings
   * for pre-production vs. production tags
   */
  private createAnalyticsTag(): void {
    const scriptTag = this.renderer2.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}&l=dataLayer${environment.gtmProps}`;

    this.renderer2.appendChild(this._document.head, scriptTag);
  }
}
