import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, finalize, first, map } from 'rxjs/operators';
import { EnvironmentSpecificService } from '../../services/environment-specific/environment-specific.service';
import { MapConstants } from '../GoogleMapConstants';
import { StoreFacade } from '../../../core/store/store/store.facade';
import { StoreRecord } from '../../../core/services/store/model/store-record';
import { FulfillmentType } from '../../../core/services/cart/models/cart-record';
import { getLatLngLiteral } from '../util/google-maps.util';
import { ActiveStoreFulfillment } from '../../../core/store/store/store.selectors';
import { NaooLogger } from '../../logger/NaooLogger.service';
import LatLngLiteral = google.maps.LatLngLiteral;
import MapOptions = google.maps.MapOptions;

export interface StoreMapMarkers {
  storeMapMarkersMap: Map<string, StoreMapMarkerOptions>;
  currentFulfillmentMarker?: StoreMapMarkerOptions;
}

export interface StoreMapMarkerOptions {
  storePlantId: string;
  storeRecord: StoreRecord;
  position: google.maps.LatLngLiteral;
}

@Injectable({ providedIn: 'root' })
export class GoogleMapsNaooService {
  private readonly mapsApiPath = 'https://maps.googleapis.com/maps/api/';
  private readonly hasLoaded$ = new BehaviorSubject<boolean>(false);
  private readonly userLatLong$ = new BehaviorSubject<LatLngLiteral>(
    MapConstants.DEFAULT_LAT_LONG,
  );
  private readonly storeMapMarkers$ = new BehaviorSubject<StoreMapMarkers>({
    storeMapMarkersMap: new Map<string, StoreMapMarkerOptions>(),
  });
  private isInitializing: boolean;

  constructor(
    private httpClient: HttpClient,
    private storeFacade: StoreFacade,
    private environmentSpecificService: EnvironmentSpecificService,
    private logger: NaooLogger,
  ) {
    combineLatest([
      storeFacade.getAllStoreRecords(),
      storeFacade.getActiveStoreFulfillment(),
    ]).subscribe(([storeRecords, activeStoreFulfillment]) => {
      this.createStoreMapMarkers(storeRecords, activeStoreFulfillment);
    });
  }

  hasLoaded(): Observable<boolean> {
    return this.hasLoaded$.asObservable();
  }

  getUserLatLongLiteral(): Observable<LatLngLiteral> {
    return this.userLatLong$.asObservable();
  }

  getStoreMapMarkers(): Observable<StoreMapMarkers> {
    return this.storeMapMarkers$.asObservable();
  }

  getMapOptions(): MapOptions {
    const mapId = this.environmentSpecificService.getGoogleMapsId();

    return {
      disableDefaultUI: true,
      streetViewControl: false,
      zoomControl: true,
      mapId: mapId,
    };
  }

  initialize(): void {
    if (!this.isInitializing && !this.hasLoaded$.getValue()) {
      this.isInitializing = true;
      const apiKey = this.environmentSpecificService.getGoogleMapsApiKey();
      this.httpClient
        .jsonp(
          `${this.mapsApiPath}js?key=${apiKey}&libraries=places,marker`,
          'callback',
        )
        .pipe(
          first(),
          map(() => {
            this.updateUserLatLong();
            return true;
          }),
          catchError(() => {
            this.logger.error(`Error calling ${this.mapsApiPath}`);
            return of(false);
          }),
          finalize(() => (this.isInitializing = false)),
        )
        .subscribe((loaded) => this.hasLoaded$.next(loaded));
    }
  }

  private updateUserLatLong(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatLong: LatLngLiteral = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.userLatLong$.next(currentLatLong);
        },
        (_) => {
          this.logger.info(
            'Error calling navigator.geolocation.getCurrentPosition',
          );
        },
        { timeout: 5000 },
      );
    }
  }

  private createStoreMapMarkers(
    storeRecords: StoreRecord[],
    activeStoreFulfillment: ActiveStoreFulfillment,
  ): void {
    let currentFulfillmentMarker: StoreMapMarkerOptions;
    const storeMapMarkersMap: Map<string, StoreMapMarkerOptions> = new Map();
    storeRecords?.forEach((storeRecord: StoreRecord) => {
      if (storeRecord.fulfillmentTypes.includes(FulfillmentType.PICKUP)) {
        const marker: StoreMapMarkerOptions = {
          storePlantId: storeRecord.storePlantId,
          storeRecord,
          position: getLatLngLiteral(storeRecord),
        };
        if (
          storeRecord.storePlantId ===
          activeStoreFulfillment?.store?.storePlantId
        ) {
          currentFulfillmentMarker = marker;
        }
        storeMapMarkersMap.set(marker.storePlantId, marker);
      }
    });

    this.storeMapMarkers$.next({
      storeMapMarkersMap,
      currentFulfillmentMarker,
    });
  }
}
