import { createActionGroup, emptyProps } from '@ngrx/store';
import { NotificationEntryState } from './customer-notification-preferences.state';
import {
  CustomerNotificationPreferencesRecord,
  NotificationRecord,
} from '../../services/customer-notification-preferences/models/customer-notification-preferences-record';

export const CustomerNotificationPreferencesActions = createActionGroup({
  source: 'Customer Notifications',
  events: {
    'Get Customer Notification Preferences': emptyProps(),
    'Get Customer Notification Preferences Success': (
      customerNotificationPreferences: CustomerNotificationPreferencesRecord,
    ) => ({ customerNotificationPreferences }),
    'Get Customer Notification Preferences Failure': emptyProps(),
    'Update Email Notifications': emptyProps(),
    'Add Email Notification': emptyProps(),
    'Edit Email Notification': (ordinal: number) => ({ ordinal }),
    'Delete Email Notification': (ordinal: number) => ({ ordinal }),
    'Save Email Notification': (
      emailNotificationState: NotificationEntryState,
    ) => ({ emailNotificationState }),
    'Cancel Edit Email Notification': emptyProps(),
    'Update Sms Notifications': emptyProps(),
    'Add Sms Notification': emptyProps(),
    'Edit Sms Notification': (ordinal: number) => ({ ordinal }),
    'Delete Sms Notification': (ordinal: number) => ({ ordinal }),
    'Save Sms Notification': (
      smsNotificationState: NotificationEntryState,
    ) => ({ smsNotificationState }),
    'Cancel Edit Sms Notification': emptyProps(),
    'Update ChatPrompt Notifications': emptyProps(),
    'Save ChatPrompt Notification': (
      chatPromptNotificationState: NotificationEntryState,
    ) => ({ chatPromptNotificationState }),
    'Edit ChatPrompt Notification': (ordinal: number) => ({ ordinal }),
    'Cancel Edit ChatPrompt Notification': emptyProps(),
    'Clear Customer Notifications': emptyProps(),
    'Update Customer Notification Preferences': (
      emailNotifications: NotificationRecord[],
      smsNotifications: NotificationRecord[],
      chatPromptNotification: NotificationRecord,
    ) => ({ emailNotifications, smsNotifications, chatPromptNotification }),
    'Update Customer Notification Preferences Success': (
      customerNotificationPreferences: CustomerNotificationPreferencesRecord,
    ) => ({ customerNotificationPreferences }),
    'Refresh Customer Notification Preferences': (userRefreshed?: boolean) => ({
      userRefreshed,
    }),
  },
});
