import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CustomGuideNameExistsValidator } from '../../../shared/services/validators/custom-guide-name-exists-validator.service';
import { NaooConstants } from '../../../shared/NaooConstants';
import { ErrorStateMatcher } from '@angular/material/core';
import { CreateCustomGuideErrorStateMatcher } from '../../../lists/create-custom-guide-modal/create-custom-guide-error-state-matcher';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-import-guide-create-custom-guide',
  templateUrl: './import-guide-create-custom-guide.component.html',
  styleUrls: ['./import-guide-create-custom-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatHint,
    MatError,
    MatIcon,
    TranslateModule,
  ],
})
export class ImportGuideCreateCustomGuideComponent implements OnInit {
  @Input() customGuideName = '';
  @Output() cancelImport = new EventEmitter();
  @Output() continueImport = new EventEmitter<string>();

  protected customGuideNameErrorStateMatcher: ErrorStateMatcher;
  readonly VALIDATION = {
    CUSTOM_GUIDE_NAME: {
      MAX_LENGTH: 40,
    },
  };
  createGuideForm: FormGroup;

  @ViewChild('customGuideInput', { static: true }) customGuideInput: ElementRef;

  constructor(
    private readonly customGuideNameExistsValidator: CustomGuideNameExistsValidator,
  ) {}

  ngOnInit(): void {
    this.customGuideNameErrorStateMatcher =
      new CreateCustomGuideErrorStateMatcher();

    const customGuideNameFormControl = new FormControl(
      '',
      [
        Validators.required,
        Validators.maxLength(this.VALIDATION.CUSTOM_GUIDE_NAME.MAX_LENGTH),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
      ],
      this.customGuideNameExistsValidator
        .validate()
        .bind(this.customGuideNameExistsValidator),
    );

    this.createGuideForm = new FormGroup({
      customGuideName: customGuideNameFormControl,
    });

    if (this.customGuideName.length > 0) {
      customGuideNameFormControl.setValue(this.customGuideName);
    }
    this.customGuideInput.nativeElement.focus();
  }

  closeModal(): void {
    this.cancelImport.emit();
  }

  nextStep(): void {
    const customGuideName = this.createGuideForm.value['customGuideName'];
    this.continueImport.emit(customGuideName);
  }
}
