import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterLink,
} from '@angular/router';
import { NaooConstants } from '../../shared/NaooConstants';
import { filter, takeUntil } from 'rxjs/operators';
import { OrdersTab } from '../../orders/orders/orders.component';
import { SessionFacade } from '../../core/store/session/session.facade';
import { CartFacade } from '../../core/store/cart/cart.facade';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { AsyncPipe, NgClass } from '@angular/common';
import { GenericDisableableLinkComponent } from '../../shared/generic-disableable-link/generic-disableable-link.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { OrderToolsComponent } from './order-tools/order-tools.component';
import { GoPointsLogoComponent } from '../../shared/go-points-logo/go-points-logo.component';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlag } from '../../core/services/session/models/session-record';
import { GenericMenuComponent } from '../../shared/generic-menu/generic-menu.component';
import { GenericMenuViewModel } from '../../shared/generic-menu/generic-menu.model';
import { GuidesMenuFacade } from '../../core/store/custom-guide/guides-menu.facade';

export interface SubHeaderTab {
  link: string;
  isActive: boolean;
  text: string;
}

export interface OrderLink {
  label: string;
  link: string;
  queryParams?: {
    [param: string]: string;
  };
}

@Component({
  selector: 'naoo-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    GenericDisableableLinkComponent,
    MatIcon,
    MatTabsModule,
    MatMenuModule,
    OrderToolsComponent,
    GoPointsLogoComponent,
    TranslateModule,
    AsyncPipe,
    GenericMenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @Input() isOffline: boolean;

  protected isBaymardEnabled$: Observable<boolean> =
    this.sessionFacade.isFeatureEnabled(FeatureFlag.BAYMARD_NAVIGATION);
  protected readonly guidesMenu$: Observable<GenericMenuViewModel> =
    this.guidesMenuFacade.getGuidesGenericMenu();
  protected readonly categoriesMenu$: Observable<GenericMenuViewModel> =
    this.guidesMenuFacade.getCategoriesMenu();

  protected readonly goPointsLabel = 'goPointsLabel';

  guidesLink: SubHeaderTab = {
    link: '/guides',
    isActive: false,
    text: 'MENU.GUIDES',
  };
  categoriesLink: SubHeaderTab = {
    link: '/categories',
    isActive: false,
    text: 'MENU.CATEGORIES',
  };
  isOrdersTab = false;
  protected myOrderLinks: OrderLink[] = [];
  protected hideOrderTools: boolean;
  protected isMenuOpen = false;
  protected currentUrl: string;
  protected isCartLoaded$: Observable<boolean> = this.cartFacade.isCartLoaded();

  private readonly destroyed$ = new Subject();
  private readonly storePurchaseHistoryLabel =
    'ORDERS.STORE_PURCHASE.TAB_TITLE';
  private readonly specialOrderLabel = 'ORDERS.SPECIAL.TAB_TITLE';
  private readonly invoiceLabel = 'ORDERS.INVOICES_CREDITS.TAB_TITLE';
  private readonly defaultMyOrderLinks: OrderLink[] = [
    {
      label: 'ORDERS.HISTORY.TITLE',
      link: '/orders',
      queryParams: { activeTab: OrdersTab.OrderHistory },
    },
    {
      label: this.specialOrderLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.SpecialOrders },
    },
    {
      label: this.invoiceLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.InvoicesCredits },
    },
    {
      label: this.storePurchaseHistoryLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.StorePurchaseHistory },
    },
    {
      label: this.goPointsLabel,
      link: '/orders',
      queryParams: { activeTab: OrdersTab.GoPoints },
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly sessionFacade: SessionFacade,
    private readonly cartFacade: CartFacade,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly guidesMenuFacade: GuidesMenuFacade,
  ) {}

  ngOnInit(): void {
    this.hideOrderTools =
      this.router.url === NaooConstants.CUSTOMER_UNIT_SELECTION_PATH ||
      this.router.url === NaooConstants.SCHEDULED_MAINTENANCE_PATH;

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationStart,
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: NavigationEnd | NavigationStart) => {
        if (event instanceof NavigationEnd) {
          this.setActiveTab(event);
        } else {
          this.currentUrl = event.url;
          this.changeDetectorRef.markForCheck();
        }
      });

    const hasStorePurchaseHistoryAccess$ =
      this.sessionFacade.hasStorePurchaseHistoryPermissions();
    const isLoyaltyProgramEligible$ =
      this.sessionFacade.isLoyaltyProgramEligible();
    const isSpecialOrderDashboardAccess$ =
      this.sessionFacade.isSpecialOrderDashboardAccess();

    combineLatest([
      hasStorePurchaseHistoryAccess$,
      isLoyaltyProgramEligible$,
      isSpecialOrderDashboardAccess$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ([
          hasStorePurchaseHistoryAccess,
          isLoyaltyProgramEligible,
          isSpecialOrderDashboardAccess,
        ]) => {
          const linksToHide: string[] = [];
          if (!isSpecialOrderDashboardAccess) {
            linksToHide.push(this.specialOrderLabel);
          }
          if (!isLoyaltyProgramEligible) {
            linksToHide.push(this.goPointsLabel);
          }
          if (hasStorePurchaseHistoryAccess) {
            linksToHide.push(this.invoiceLabel);
          } else {
            linksToHide.push(this.storePurchaseHistoryLabel);
          }
          this.myOrderLinks = this.defaultMyOrderLinks.filter(
            (link) => !linksToHide.includes(link.label),
          );
          this.changeDetectorRef.markForCheck();
        },
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setActiveTab(event: NavigationEnd): void {
    this.hideOrderTools =
      event.urlAfterRedirects === NaooConstants.CUSTOMER_UNIT_SELECTION_PATH ||
      this.router.url === NaooConstants.SCHEDULED_MAINTENANCE_PATH;

    this.isOrdersTab = event.url.includes('/orders');
    this.categoriesLink.isActive =
      event.urlAfterRedirects.includes('/categories') ||
      event.url.includes('/search');
    this.guidesLink.isActive = event.urlAfterRedirects.includes('/guides');
    this.changeDetectorRef.markForCheck();
  }
}
