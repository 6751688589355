<div class="navigation-wrapper">
  <nav
    mat-tab-nav-bar
    disableRipple="true"
    mat-stretch-tabs="false"
    [tabPanel]="tabPanel"
  >
    @if (isBaymardEnabled$ | async) {
      <naoo-generic-menu
        class="guides-menu"
        [menuModel]="guidesMenu$ | async"
      ></naoo-generic-menu>

      <naoo-generic-menu
        class="categories-menu"
        [menuModel]="categoriesMenu$ | async"
        [isOffline]="isOffline"
      ></naoo-generic-menu>
    } @else {
      <a
        class="mat-mdc-tab-link guides-tab"
        role="tab"
        [routerLink]="guidesLink.link"
        [ngClass]="{ focused: guidesLink.isActive }"
      >
        {{ guidesLink.text | translate }}
      </a>

      <naoo-generic-disableable-link
        [ngClass]="{
          'mat-mdc-tab-link': true,
          focused: categoriesLink.isActive,
          disabled: isOffline,
        }"
        [routingUrl]="categoriesLink.link"
        [isDisabled]="isOffline"
        role="tab"
      >
        {{ categoriesLink.text | translate }}
      </naoo-generic-disableable-link>
    }
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  <div class="action-wrapper">
    <button
      id="ordersBtn"
      [attr.aria-label]="'MENU.ORDERS' | translate"
      class="mat-mdc-tab-link orders-button"
      [ngClass]="{ focused: isOrdersTab, disabled: isOffline }"
      [matMenuTriggerFor]="myOrderMenu"
      (menuClosed)="isMenuOpen = false"
      (menuOpened)="isMenuOpen = true"
      [disabled]="isOffline"
    >
      <mat-icon class="truck-icon" svgIcon="truck"></mat-icon>
      <span>{{ 'MENU.ORDERS' | translate }}</span>
      <mat-icon
        [ngClass]="{ 'rotate-arrow': isMenuOpen }"
        class="dropdown-arrow-icon"
        svgIcon="arrow-v2"
      ></mat-icon>
    </button>
    @if (!hideOrderTools) {
      <naoo-order-tools
        class="sub-header-action"
        [ngClass]="{ disabled: isOffline || !(isCartLoaded$ | async) }"
        id="order-tools"
        [isDisabled]="isOffline || !(isCartLoaded$ | async)"
      ></naoo-order-tools>
    }
  </div>
</div>

<mat-menu #myOrderMenu="matMenu" xPosition="before">
  <div class="subheader-menu">
    @for (link of myOrderLinks; track link.label) {
      <a
        class="subheader-menu-item"
        mat-menu-item
        [attr.aria-label]="link.label | translate"
        [routerLink]="link.link"
        [queryParams]="link.queryParams"
        [ngClass]="{
          active:
            !!link.queryParams &&
            !!currentUrl &&
            currentUrl.includes(link.queryParams.activeTab),
        }"
      >
        @if (link.label === goPointsLabel) {
          <naoo-go-points-logo
            [context]="'naoo-sub-header'"
          ></naoo-go-points-logo>
        } @else {
          {{ link.label | translate }}
        }
      </a>
    }
  </div>
</mat-menu>
