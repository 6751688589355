import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomGuideActions } from './custom-guide.actions';
import {
  CustomGuideInfo,
  CustomGuideMaterialParOrder,
  selectAllCustomGuideInfoRecords,
  selectAllCustomGuideRecords,
  selectCategorizedCustomGuide,
  selectCustomGuide,
  selectCustomGuideExportMaterialsInput,
  selectCustomGuideMaterialParOrders,
  selectCustomGuideRecord,
  selectHasCustomGuidesLoaded,
} from './custom-guide.selectors';
import { CustomGuideRecord } from '../../services/custom-guide/model/custom-guide-record';
import { Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { CustomGuideService } from '../../services/custom-guide/custom-guide.service';
import { CategorizedCustomGuide } from '../../../shared/models/categorized-materials';
import { sortBy } from 'lodash-es';
import {
  CustomGuideCategoryRequest,
  CustomGuideRequest,
} from '../../services/custom-guide/model/custom-guide-request';
import { CustomGuide } from './models/custom-guide';
import { GroupByType, SortByType } from '../../../guides/shared/guides';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';

@Injectable({ providedIn: 'root' })
export class CustomGuideFacade {
  constructor(
    private readonly store: Store,
    private readonly customGuideService: CustomGuideService,
  ) {}

  isValidCustomGuide(id: string): Observable<boolean> {
    return this.store.select(selectHasCustomGuidesLoaded).pipe(
      filter((hasLoaded) => hasLoaded),
      withLatestFrom(this.store.select(selectCustomGuideRecord(id))),
      map(([_, record]) => !!record),
    );
  }

  getCustomGuide(id: string): Observable<CustomGuide> {
    return this.store.select(selectCustomGuide(id));
  }

  getAllLoadedSortedCustomGuideInfoRecords(): Observable<CustomGuideInfo[]> {
    return this.store.select(selectAllCustomGuideInfoRecords).pipe(
      withLatestFrom(this.store.select(selectHasCustomGuidesLoaded)),
      filter(([_, hasLoaded]) => hasLoaded),
      map(([records, _]) =>
        sortBy(records, (customGuide) => customGuide.name.toLowerCase()),
      ),
    );
  }

  getAllLoadedSortedCustomGuides(): Observable<CustomGuideRecord[]> {
    return this.store.select(selectAllCustomGuideRecords).pipe(
      withLatestFrom(this.store.select(selectHasCustomGuidesLoaded)),
      filter(([_, hasLoaded]) => hasLoaded),
      map(([records, _]) =>
        sortBy(records, (customGuideInfo) =>
          customGuideInfo.name.toLowerCase(),
        ),
      ),
    );
  }

  getCategorizedCustomGuide(id: string): Observable<CategorizedCustomGuide> {
    return this.store.select(selectCategorizedCustomGuide(id));
  }

  getCustomGuideRecord(id: string): Observable<CustomGuideRecord> {
    return this.store.select(selectCustomGuideRecord(id));
  }

  getCustomGuideMaterialParOrders(
    id: string,
  ): Observable<Map<string, CustomGuideMaterialParOrder>> {
    return this.store.select(selectCustomGuideMaterialParOrders(id));
  }

  createCustomGuide(
    name: string,
    groupBy: GroupByType,
    materialNumbers: string[] = [],
  ): void {
    this.store.dispatch(
      CustomGuideActions.createCustomGuide(name, groupBy, materialNumbers),
    );
  }

  importCustomGuide(
    name: string,
    categories: CustomGuideCategoryRequest[],
  ): Observable<CustomGuideRecord> {
    const request: CustomGuideRequest = {
      name,
      sortBy: SortByType.Custom,
      groupBy: GroupByType.Custom,
      parOrderingEnabled: false,
      categories,
    };
    // This is breaking the NgRx pattern to account for a 1:1 code swap for importGuideModal
    return this.customGuideService.createCustomGuide(request).pipe(
      tap((record) => {
        this.store.dispatch(
          CustomGuideActions.importCustomGuideSuccess(record),
        );
      }),
    );
  }

  duplicateCustomGuide(id: string, name: string, groupBy: GroupByType): void {
    this.store.dispatch(
      CustomGuideActions.duplicateCustomGuide(id, name, groupBy),
    );
  }

  copyCustomGuide(id: string, compositeCustomerIds: string[]) {
    // This is breaking the NgRx pattern to account for a 1:1 code swap for copying the custom guide
    return this.customGuideService.copyCustomGuide(id, compositeCustomerIds);
  }

  deleteCustomGuide(id: string): void {
    this.store.dispatch(CustomGuideActions.deleteCustomGuide(id));
  }

  renameCustomGuide(id: string, name: string): void {
    this.store.dispatch(CustomGuideActions.renameCustomGuide(id, name));
  }

  updateSortBy(id: string, sortByType: SortByType) {
    this.store.dispatch(
      CustomGuideActions.updateCustomGuideSortBy(id, sortByType),
    );
  }

  updateSearchText(id: string, searchText: string): void {
    this.store.dispatch(
      CustomGuideActions.updateCustomGuideSearchText(id, searchText),
    );
  }

  updateGroupBy(id: string, groupBy: GroupByType) {
    this.store.dispatch(
      CustomGuideActions.updateCustomGuideGroupBy(id, groupBy),
    );
  }

  createCustomGuideCategory(id: string, name: string): void {
    this.store.dispatch(CustomGuideActions.createCustomGuideCategory(id, name));
  }

  renameCustomGuideCategory(
    id: string,
    categoryIndex: number,
    name: string,
  ): void {
    this.store.dispatch(
      CustomGuideActions.renameCustomGuideCategory(id, categoryIndex, name),
    );
  }

  removeCustomGuideCategory(id: string, categoryIndex: number): void {
    this.store.dispatch(
      CustomGuideActions.removeCustomGuideCategory(id, categoryIndex),
    );
  }

  moveCustomGuideCategory(
    id: string,
    originalCategoryIndex: number,
    newCategoryIndex: number,
  ): void {
    this.store.dispatch(
      CustomGuideActions.moveCustomGuideCategory(
        id,
        originalCategoryIndex,
        newCategoryIndex,
      ),
    );
  }

  addCustomGuideMaterials(id: string, materialNumbers: string[]) {
    this.store.dispatch(
      CustomGuideActions.addCustomGuideMaterials(id, materialNumbers),
    );
  }

  removeCustomGuideMaterials(id: string, materialNumbers: string[]) {
    this.store.dispatch(
      CustomGuideActions.removeCustomGuideMaterials(id, materialNumbers),
    );
  }

  moveCustomGuideMaterials(
    id: string,
    materialNumber: string,
    newCategoryIndex: number,
    newMaterialIndex: number,
  ) {
    this.store.dispatch(
      CustomGuideActions.moveCustomGuideMaterials(
        id,
        materialNumber,
        newCategoryIndex,
        newMaterialIndex,
      ),
    );
  }

  updateParQuantity(
    id: string,
    materialNumber: string,
    uom: string,
    quantity: number,
  ): void {
    this.store.dispatch(
      CustomGuideActions.updateParQuantity(id, materialNumber, uom, quantity),
    );
  }

  updateInventoryQuantity(
    id: string,
    materialNumber: string,
    uom: string,
    quantity: number,
  ): void {
    this.store.dispatch(
      CustomGuideActions.updateInventoryQuantity(
        id,
        materialNumber,
        uom,
        quantity,
      ),
    );
  }

  clearInventoryQuantities(id: string): void {
    this.store.dispatch(CustomGuideActions.clearInventoryQuantities(id));
  }

  toggleParOrdering(id: string, parOrderingEnabled: boolean): void {
    this.store.dispatch(
      CustomGuideActions.toggleParOrdering(id, parOrderingEnabled),
    );
  }

  loadCategorizedCustomGuide(id: string, groupBy: GroupByType): void {
    this.store.dispatch(
      CustomGuideActions.getCategorizedCustomGuide(id, groupBy),
    );
  }

  clearCategorizedCustomGuide(id: string): void {
    this.store.dispatch(CustomGuideActions.clearCategorizedCustomGuide(id));
  }

  getExportMaterialsInput(
    customGuideId: string,
    isPrint: boolean,
  ): Observable<ExportMaterialsInput> {
    return this.store.select(
      selectCustomGuideExportMaterialsInput(customGuideId, isPrint),
    );
  }
}
