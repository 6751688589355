import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'naoo-error-state',
  templateUrl: './error-state.component.html',
  styleUrls: ['./error-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ErrorStateComponent {
  constructor(private readonly location: Location) {}

  @Input() alt: string;
  @Input() errorImageSource: string;
  @Input() errorTitle: string;
  @Input() errorSubtitle: string;
  @Input() errorButtonTitle: string;
  @Input() showErrorButton = true;

  navigateBack(): void {
    this.location.back();
  }
}
