import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export interface MessageData {
  messageKey: string;
  parameters?: object;
}

export interface ImageData {
  imageLocation: string;
  altText: string;
}

export interface GenericModalComponentData {
  messageData: string | MessageData;
  helperMessageData: string | MessageData;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  closeAction: () => void;
  isDestructive: boolean;
  image: ImageData;
  title: string;
}

@Component({
  selector: 'naoo-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogContent,
    NgTemplateOutlet,
    MatButton,
    MatIcon,
    NgClass,
    TranslateModule,
  ],
})
export class GenericModalComponent implements OnInit {
  protected messageData: MessageData | null;
  protected helperMessageData: MessageData | null;
  protected primaryButtonText: string;
  protected secondaryButtonText: string;
  protected primaryButtonAction: () => void;
  protected secondaryButtonAction: () => void;
  protected closeAction: () => void;
  protected isDestructive: boolean;
  protected image: ImageData;
  protected title: string;

  constructor(
    private readonly dialogRef: MatDialogRef<GenericModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: GenericModalComponentData,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.messageData = this.parseMessageData(this.data.messageData);
    this.helperMessageData = this.parseMessageData(this.data.helperMessageData);
    this.primaryButtonText = this.data.primaryButtonText;
    this.secondaryButtonText = this.data.secondaryButtonText;
    this.primaryButtonAction = this.data.primaryButtonAction;
    this.secondaryButtonAction = this.data.secondaryButtonAction;
    this.closeAction = this.data.closeAction;
    this.isDestructive = this.data.isDestructive;
    this.image = this.data.image;
    this.title = this.data.title;
    this.changeDetectorRef.markForCheck();
  }

  private parseMessageData(
    messageData: string | MessageData,
  ): MessageData | null {
    if (messageData && typeof messageData === 'object') {
      return messageData;
    } else if (typeof messageData === 'string') {
      return {
        messageKey: messageData,
      };
    }
    return null;
  }

  get hasVideoData(): boolean {
    return this.image?.imageLocation.endsWith('.mp4');
  }

  close(): void {
    this.dialogRef.close();
  }
}
