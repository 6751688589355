import { createSelector } from '@ngrx/store';
import { NaooConstants } from '../../../shared/NaooConstants';
import { sortBy } from 'lodash-es';
import {
  selectAllCustomGuideInfoRecords,
  selectHasCustomGuidesLoaded,
} from './custom-guide.selectors';
import { GenericMenuViewModel } from '../../../shared/generic-menu/generic-menu.model';

export const selectGuidesGenericMenu = createSelector(
  selectAllCustomGuideInfoRecords,
  selectHasCustomGuidesLoaded,
  (customGuidesInfo, loaded): GenericMenuViewModel => ({
    title: 'MENU.GUIDES',
    ariaLabel: 'MENU.GUIDES',
    topLevel: true,
    icon: NaooConstants.icons.order_approve_icon.name,
    menuElements: [
      {
        name: 'MENU.ORDER_GUIDE',
        route: NaooConstants.ORDER_GUIDE_PATH,
        isViewAll: false,
      },
      {
        name: 'LISTS.CUSTOM_GUIDE',
        isViewAll: false,
        route:
          loaded && customGuidesInfo?.length > 0
            ? undefined
            : NaooConstants.CUSTOM_GUIDE_PATH,
        menuElements: sortBy(customGuidesInfo, (customGuide) =>
          customGuide.name.toLowerCase(),
        ).map((customGuide) => ({
          name: {
            es: customGuide.name,
            en: customGuide.name,
            fr: customGuide.name,
          },
          route: `${NaooConstants.CUSTOM_GUIDE_PATH}/${customGuide.id}`,
          isViewAll: false,
        })),
      },
      {
        name: 'CRITICAL_ITEMS.TITLE',
        route: NaooConstants.CRITICAL_ITEMS_GUIDE_PATH,
        isViewAll: false,
      },
      {
        name: undefined,
        route: NaooConstants.GUIDES_PATH,
        isViewAll: true,
      },
    ],
    maxDisplayed: 9,
  }),
);
