import { EMPTY, Observable } from 'rxjs';
import { GenericMenuViewModel } from '../../../shared/generic-menu/generic-menu.model';
import { selectGuidesGenericMenu } from './guides-menu.selectors';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CatalogService } from '../../../shared/services/catalog/catalog.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { NaooConstants } from '../../../shared/NaooConstants';
import { SessionFacade } from '../session/session.facade';
import { FeatureFlag } from '../../services/session/models/session-record';

@Injectable({ providedIn: 'root' })
export class GuidesMenuFacade {
  private readonly rootCategoryKey: string = '0';

  constructor(
    private readonly store: Store,
    private readonly catalogService: CatalogService,
    private readonly sessionFacade: SessionFacade,
  ) {}

  getGuidesGenericMenu(): Observable<GenericMenuViewModel> {
    return this.sessionFacade
      .isFeatureEnabled(FeatureFlag.BAYMARD_NAVIGATION)
      .pipe(
        switchMap((isEnabled: boolean) => {
          if (isEnabled) {
            return this.store.select(selectGuidesGenericMenu);
          }
          return EMPTY;
        }),
      );
  }

  getCategoriesMenu(): Observable<GenericMenuViewModel> {
    return this.sessionFacade
      .isFeatureEnabled(FeatureFlag.BAYMARD_NAVIGATION)
      .pipe(
        switchMap((isEnabled: boolean) => {
          if (isEnabled) {
            return this.getCategoriesMenuModel();
          }
          return EMPTY;
        }),
      );
  }

  private getCategoriesMenuModel(): Observable<GenericMenuViewModel> {
    this.catalogService.loadCategoryTree(this.rootCategoryKey);
    return this.catalogService
      .observeCategoryResponse(this.rootCategoryKey)
      .pipe(
        filter((superCategory) => superCategory != null),
        map((categoriesFromRoot) => {
          const categoriesMenu: GenericMenuViewModel = {
            title: 'MENU.CATEGORIES',
            ariaLabel: 'MENU.CATEGORIES',
            topLevel: true,
            icon: NaooConstants.icons.categories_icon.name,
            menuElements: [
              {
                name: undefined,
                route: NaooConstants.CATEGORIES_PATH,
                isViewAll: true,
              },
            ],
          };

          categoriesFromRoot.subCategories.forEach((category) => {
            categoriesMenu.menuElements.push({
              name: category.name,
              isViewAll: false,
              route: `${NaooConstants.CATEGORIES_PATH}/${category.categoryKey}`,
            });
          });

          return categoriesMenu;
        }),
      );
  }
}
