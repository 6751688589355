import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NaooConstants } from '../../shared/NaooConstants';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class HamburgerMenuComponent {
  constructor(private readonly router: Router) {}

  navigateToHamburgerMenu(): void {
    this.router.navigate([NaooConstants.MOBILE_HAMBURGER_MENU_PATH], {
      queryParams: { from: this.router.url },
    });
  }
}
