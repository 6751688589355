import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'naoo-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule, MatIcon],
})
export class ModalHeaderComponent {
  @Input() title: string;
  @Input() showCloseButton: boolean;
  @Input() stickyHeader?: boolean;
  @Input() isMobile?: boolean;
  @Input() showBackArrow?: boolean;
  @Output() backActionEmitter = new EventEmitter();
  @Output() closeModalEmitter = new EventEmitter();

  @ViewChild('focusableButton') focusableButton: ElementRef;

  focusCloseButton(): void {
    if (this.showCloseButton) {
      this.focusableButton.nativeElement.focus();
    }
  }

  closeModal(): void {
    this.closeModalEmitter.emit();
  }

  backActionModal(): void {
    this.backActionEmitter.emit();
  }
}
