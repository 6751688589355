<naoo-modal-header
  [title]="'RE_ORDER.MODAL_TITLE' | translate"
  [showCloseButton]="showCloseButton"
  (closeModalEmitter)="onClose()"
></naoo-modal-header>
<div class="step-container">
  <naoo-unavailable-products
    [validationResult]="validationResult"
    (cancelAddingItemsToCart)="onCancel()"
    (addItemsToCart)="onContinueUnavailableMaterials(true)"
  ></naoo-unavailable-products>
</div>
