@if (isLoading$ | async) {
  <mat-progress-bar class="loading-bar" mode="indeterminate"></mat-progress-bar>
}
@if (!isOfflineBannerVisible) {
  <naoo-banner-message (click)="clicked()"></naoo-banner-message>
}
<naoo-banner-offline-container
  (visibilityChanged)="setOfflineBannerVisible($event)"
></naoo-banner-offline-container>
@if (!(isMobile$ | async)) {
  <div class="top-section">
    <div class="boxed-content">
      @if (!showThinHeader) {
        <naoo-header-tabs [isDisabled]="isOffline"></naoo-header-tabs>
      }
      <div class="right-side">
        @if (!showThinHeader) {
          <naoo-customer-unit-selector
            [isDisabled]="isOffline"
            [ngClass]="{
              disabled: isOffline,
            }"
          ></naoo-customer-unit-selector>
        }
        <naoo-account-menu
          class="sub-header-action"
          [isDisabled]="isOffline"
          [isOffline]="isOffline"
          [customerBrand]="customerBrand"
          [ngClass]="{
            disabled: isOffline,
          }"
        ></naoo-account-menu>
      </div>
    </div>
  </div>
  <div class="mid-section">
    <div class="boxed-content">
      <naoo-generic-disableable-link
        class="logo"
        className="logo"
        [routingUrl]="HOME_PAGE_PATH"
        (clickEvent)="trackLogoClick()"
        [isDisabled]="isOffline"
      >
        @if (isOffline) {
          <img
            class="black-logo-img"
            src="{{ 'LOGO.STATIC' | naoobrand: customerBrand | translate }}"
            alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
          />
        } @else {
          <img
            src="{{ 'LOGO.URL' | naoobrand: customerBrand | translate }}"
            alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
          />
        }
      </naoo-generic-disableable-link>
      @if (!showThinHeader) {
        <naoo-search-bar
          [isMobile]="false"
          [isDisabled]="isOffline"
          #searchBar
        ></naoo-search-bar>
        @if (isSapWithPermissions) {
          <naoo-select-order-method-widget
            [ngClass]="{
              disabled: isOffline,
            }"
            [disabled]="isOffline"
          ></naoo-select-order-method-widget>
        } @else {
          <naoo-delivery-schedule-widget
            [ngClass]="{
              disabled: isOffline,
            }"
            [disabled]="isOffline"
          ></naoo-delivery-schedule-widget>
        }
        <div class="cart">
          <naoo-cart-icon class="cart"></naoo-cart-icon>
        </div>
      }
    </div>
  </div>
  @if (!showThinHeader) {
    <div class="bottom-section">
      <div class="boxed-content">
        <naoo-sub-header
          [isOffline]="isOffline"
          class="naoo-sub-header"
        ></naoo-sub-header>
      </div>
    </div>
  }
} @else {
  <header>
    <div class="header mid-section">
      <naoo-hamburger-menu class="hamburger-container"></naoo-hamburger-menu>
      <naoo-generic-disableable-link
        class="logo"
        className="logo"
        [routingUrl]="HOME_PAGE_PATH"
        (clickEvent)="trackLogoClick()"
        [isDisabled]="isOffline"
      >
        @if (!isOffline && !showBlackLogo) {
          <img
            src="{{ 'LOGO.URL' | naoobrand: customerBrand | translate }}"
            alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
          />
        } @else {
          <img
            class="black-logo-img"
            src="{{ 'LOGO.STATIC' | naoobrand: customerBrand | translate }}"
            alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
          />
        }
      </naoo-generic-disableable-link>
      @if (!showThinHeader) {
        <div class="cart">
          <naoo-search-button-mobile
            [isDisabled]="isOffline"
          ></naoo-search-button-mobile>
          @if (isSapWithPermissions) {
            <naoo-select-order-method-widget
              [ngClass]="{
                disabled: isOffline,
              }"
              [disabled]="isOffline"
            ></naoo-select-order-method-widget>
          }
          <naoo-cart-icon [isMobile]="isMobile$ | async"></naoo-cart-icon>
        </div>
      }
    </div>
  </header>
}
