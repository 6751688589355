import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { HeaderLinkType, MoreToolsLink } from '../header.types';
import { naooAnimations } from '../../shared/animations/animations';
import {
  CustomerPermission,
  Language,
} from '../../core/services/session/models/session-record';
import { SessionFacade } from 'src/app/core/store/session/session.facade';
import { LocalizationService } from '../../shared/services/translation/localization.service';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'naoo-more-tools',
  templateUrl: './more-tools.component.html',
  styleUrls: ['./more-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [naooAnimations.showHide],
  standalone: true,
  imports: [MatIcon, TranslateModule, NgClass, MatMenuModule],
})
export class MoreToolsComponent implements OnInit, OnDestroy {
  protected readonly localizationService = inject(LocalizationService);
  private readonly reportingTitle = 'TITLE.GORDON_REPORTING';
  protected isMenuOpen = signal<boolean>(false);
  protected showHideAnimationStatus = signal<string>('hide');
  private isCA: boolean = false;
  @Input() permissions: CustomerPermission[];
  @Input() isDisabled: boolean;
  @Input() isMobile: boolean;
  @Output() headerRoutingEvent = new EventEmitter<HeaderLinkType>();

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly sessionFacade: SessionFacade,
    private readonly changeDetectRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.sessionFacade
      .getLoadedCountryCode()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((countryCode) => {
        this.isCA = countryCode === 'CA';
        this.changeDetectRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  redirectTo(name: HeaderLinkType): void {
    this.headerRoutingEvent.emit(name);
  }

  toggleSubMenu(): void {
    this.isMenuOpen.set(!this.isMenuOpen());
    this.showHideAnimationStatus.set(this.isMenuOpen() ? 'show' : 'hide');
  }

  get moreToolsLinks(): Record<string, MoreToolsLink> {
    return {
      [CustomerPermission.GordonRestaurantProAccess]: {
        translationName: 'TITLE.GORDON_RESTAURANT_PRO',
        headerLinkType: HeaderLinkType.GORDON_RESTAURANT_PRO,
      },
      [CustomerPermission.OnlinePaymentAccess]: {
        translationName: 'TITLE.ONLINE_PAYMENT',
        headerLinkType: HeaderLinkType.ONLINE_PAYMENT,
      },
      [CustomerPermission.CycleMenuManagementAccess]: {
        translationName: 'TITLE.CYCLE_MENU_MANAGEMENT',
        headerLinkType: HeaderLinkType.CYCLE_MENU_MANAGEMENT,
      },
      [CustomerPermission.DiningRdAccess]: {
        translationName: 'TITLE.DINING_RD',
        headerLinkType: this.isCA
          ? HeaderLinkType.DINING_RD_CA
          : HeaderLinkType.DINING_RD,
      },
      [CustomerPermission.GordonReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_REPORTING_FR
            : HeaderLinkType.GORDON_REPORTING,
      },
      [CustomerPermission.GordonEducationReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_EDUCATION_REPORTING_FR
            : HeaderLinkType.GORDON_EDUCATION_REPORTING,
      },
      [CustomerPermission.GordonHealthReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_HEALTH_REPORTING_FR
            : HeaderLinkType.GORDON_HEALTH_REPORTING,
      },
      [CustomerPermission.ExperienceAccess]: {
        translationName: 'TITLE.EXPERIENCE',
        headerLinkType: HeaderLinkType.EXPERIENCE,
      },
      [CustomerPermission.ResourcesAccess]: {
        translationName: 'TITLE.RESOURCES',
        headerLinkType: HeaderLinkType.RESOURCES,
      },
    };
  }
}
