import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NaooConstants } from '../../shared/NaooConstants';
import { NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { CustomerUnitInfoComponent } from '../customer-unit-info/customer-unit-info.component';

@Component({
  selector: 'naoo-customer-unit-selector',
  templateUrl: './customer-unit-selector.component.html',
  styleUrls: ['./customer-unit-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, RouterLink, MatIcon, CustomerUnitInfoComponent],
})
export class CustomerUnitSelectorComponent {
  @Input() showArrowIcon = false;
  @Input() isDisabled = false;

  protected readonly customerUnitRoute =
    NaooConstants.CUSTOMER_UNIT_SELECTION_PATH;
}
