import LatLngLiteral = google.maps.LatLngLiteral;
import Icon = google.maps.Icon;

export class MapConstants {
  public static DEFAULT_ZOOM: number = 11;
  public static ICON_URL: string = 'https://www.google.com/mapfiles/marker.png';
  public static DEFAULT_LAT_LONG: LatLngLiteral /** Clay Avenue Store **/ = {
    lat: 42.8734667,
    lng: -85.6776895,
  };
  public static DEFAULT_ICON: Icon = {
    url: this.ICON_URL,
  };
  public static LARGE_ICON: Icon = {
    url: this.ICON_URL,
    scaledSize: {
      height: 45,
      width: 27,
    } as google.maps.Size,
  };
}
