import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FileInputComponent } from '../../../file-input/file-input.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FileInputComponent, TranslateModule],
})
export class ImportFileComponent {
  @Input() csvTemplate: string;
  @Input() excelTemplate: string;
  @Input() fileRequirements: string[];
  @Input() imagePath: string;
  @Input() title: string;
  @Output() fileSelected = new EventEmitter<Blob>();
  @Output() templateDownload = new EventEmitter<string>();

  onFileSelected(file: Blob): void {
    this.fileSelected.emit(file);
  }

  onTemplateDownload(type: string): void {
    this.templateDownload.emit(type);
  }
}
