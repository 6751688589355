import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ImportOrderRow,
  ImportOrderRowErrors,
} from '../../../shared/models/import-order-row';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { Locale } from 'src/app/core/services/session/models/session-record';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-import-order-error-table',
  templateUrl: './import-order-error-table.component.html',
  styleUrls: ['./import-order-error-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, TranslateModule],
})
export class ImportOrderErrorTableComponent {
  @Input() importOrderRows: ImportOrderRow[];
  @Input() includeCustomerMaterial: boolean;

  constructor(private readonly localizationService: LocalizationService) {}

  get shouldShortenTable(): boolean {
    return this.localizationService.currentLocale !== Locale.en_CA;
  }

  importOrderErrorMessage(error: ImportOrderRowErrors): string {
    switch (error) {
      case ImportOrderRowErrors.TooManyCases:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.TOO_MANY_CASES';
      case ImportOrderRowErrors.TooManyUnits:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.TOO_MANY_UNITS';
      case ImportOrderRowErrors.EmptyId:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.EMPTY_ID';
      case ImportOrderRowErrors.CasesNotNumeric:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.CASES_NOT_NUMERIC';
      case ImportOrderRowErrors.UnitsNotNumeric:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.UNITS_NOT_NUMERIC';
      case ImportOrderRowErrors.NegativeCase:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.NEGATIVE_CASE';
      case ImportOrderRowErrors.NegativeUnit:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.NEGATIVE_UNIT';
      case ImportOrderRowErrors.FractionalCase:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.CASES_NOT_NUMERIC';
      case ImportOrderRowErrors.FractionalUnit:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.UNITS_NOT_NUMERIC';
      case ImportOrderRowErrors.TooManyMergedCases:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.TOO_MANY_MERGED_CASES';
      case ImportOrderRowErrors.TooManyMergedUnits:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.TOO_MANY_MERGED_UNITS';
      case ImportOrderRowErrors.NotAvailable:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.NOT_AVAILABLE';
      case ImportOrderRowErrors.NotUnitOrderable:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.NOT_UNIT_ORDERABLE';
      case ImportOrderRowErrors.NoLongerAvailable:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.NO_LONGER_AVAILABLE';
      case ImportOrderRowErrors.WrongBreakCase:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.WRONG_BREAK_CASE';
      case ImportOrderRowErrors.NotNumeric:
        return 'IMPORT_ORDER.ROW_ERROR.MESSAGE.NOT_NUMERIC_ID';
      default:
        return '';
    }
  }
}
