import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  selectSpecialOrderRows,
  SpecialOrderInfo,
} from './special-orders.selectors';
import { SpecialOrdersActions } from './special-orders.actions';
import { LocalizationService } from '../../../shared/services/translation/localization.service';
import { NaooStringDefaulter } from '../../../shared/string-defaulter/naoo-string-defaulter';
import { SpecialOrderRowViewModel } from '../../../orders/orders/special-order/special-order-row-view-model';

@Injectable({ providedIn: 'root' })
export class SpecialOrdersFacade {
  constructor(
    private readonly store: Store,
    private readonly naooStringDefaulter: NaooStringDefaulter,
    private readonly localizationService: LocalizationService,
  ) {}

  getLoadedSpecialOrderInfo(): Observable<SpecialOrderInfo> {
    return this.store
      .select(selectSpecialOrderRows)
      .pipe(filter((specialOrderInfo) => !!specialOrderInfo));
  }

  refreshSpecialOrders() {
    this.store.dispatch(SpecialOrdersActions.refreshSpecialOrders());
  }

  generateSearchableStrings(row: SpecialOrderRowViewModel) {
    const language = this.localizationService.currentLanguage;
    const searchableStrings = [
      this.naooStringDefaulter.getCurrentLanguageOnlyString(
        row.statusDescription,
        language,
      ),
      this.naooStringDefaulter.getCurrentLanguageOnlyString(
        row.statusSecondaryDescription,
        language,
      ),
      row.materialNumber,
      this.naooStringDefaulter.getCurrentLanguageOnlyString(
        row.materialInfo?.description,
        language,
      ),
      row.poNumber,
      row.salesOrderNumber,
      row.orderType,
    ];

    row.searchableStrings = searchableStrings
      .filter((element) => element?.length)
      .map((field) => field.toLowerCase());
  }
}
