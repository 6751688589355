import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialListStyle } from '../../../../core/store/material-row/models/material-row';
import { NgClass } from '@angular/common';

@Component({
  selector: 'naoo-material-skeleton',
  template: `
    <div
      class="skeleton"
      [ngClass]="{ mobile: isMobile }"
      [class]="materialViewStyleClass"
    ></div>
  `,
  styleUrls: ['./material-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class MaterialSkeletonComponent {
  @Input() isMobile: boolean;
  @Input() materialListStyle: MaterialListStyle;

  get materialViewStyleClass(): string {
    switch (this.materialListStyle) {
      case MaterialListStyle.List:
        return 'list';
      case MaterialListStyle.Grid:
        return 'grid';
      case MaterialListStyle.Slim:
        return 'slim';
    }
    return undefined;
  }
}
