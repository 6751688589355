import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-file-error',
  templateUrl: './file-error.component.html',
  styleUrls: ['./file-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class FileErrorComponent {
  @Input() fileError: string;
  @Input() fileName: string;
  @Input() csvTemplate: string;
  @Input() excelTemplate: string;
  @Input() fileRequirements: string[];
  @Input() errorImage: string;
  @Output() startOver = new EventEmitter();
  @Output() templateDownload = new EventEmitter<string>();

  onStartOver(): void {
    this.startOver.emit();
  }

  onTemplateDownload(type: string): void {
    this.templateDownload.emit(type);
  }
}
