import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HeaderLinkType } from '../../../header/header.types';
import { CustomerBrand } from '../../../core/services/session/models/session-record';
import GordonNowProperties from 'gordon-now-chat.json';

export enum Environment {
  wiremock = 'wiremock',
  local = 'local',
  dev = 'dev',
  tst = 'tst',
  qat = 'qat',
  sit = 'sit',
  prd = 'prd',
}

interface NaooEnvironments {
  wiremock: EnvironmentConfig;
  local: EnvironmentConfig;
  dev: EnvironmentConfig;
  tst: EnvironmentConfig;
  qat: EnvironmentConfig;
  sit: EnvironmentConfig;
  prd: EnvironmentConfig;
}

interface EnvironmentConfig {
  logRocketAppId: string;
  adZerkUrl: string;
  chatChannelEnv: string;
  headerUrls: Record<HeaderLinkType, string>;
  googleMapsApiKey: string;
  googleMapId: string;
}

@Injectable({ providedIn: 'root' })
export class EnvironmentSpecificService {
  private logRocketPrefix = 'fwixe9/naoo-';
  private adZerkUrl = 'https://digitalcontent.gfs.com/api/v2';
  private adZerkWiremock = '/api/digital-content/adzerk';
  private inventoryStagingUrl = 'https://inventory-staging.gfs.com';
  private inventoryProdUrl = 'https://inventory.gfs.com';
  private recipeStagingUrl = 'https://recipe-staging.gfs.com';
  private recipeProdUrl = 'https://recipe.gfs.com';
  private experienceStagingUrl = 'https://experiencesit.gfs.com/';
  private experienceProdUrl = 'https://experience.gfs.com/';
  private resourcesStagingUrl = 'https://experiencesit.gfs.com/resources';
  private resourcesProdUrl = 'https://experience.gfs.com/resources';
  private aciPaymentsStagingUrl = 'https://eu-test.oppwa.com';
  private aciPaymentsProdUrl = 'https://eu-prod.oppwa.com';
  private ecomContentProdUrl = 'https://ecomcontent.gfs.com';
  private ecomContentStagingUrl = 'https://dev.ecomcontent.gfs.com';
  private onlinePaymentStagingUrl =
    'https://sso-staging.gfs.com/home/gordonfoodservicestaging_onlinepaymentsit_1/0oaufxho9sGhXqVGI0h7/alnufxkfjbGdn4XO20h7';
  private onlinePaymentProdUrl =
    'https://sso.gfs.com/home/gordonfoodservice_onlinepayment_1/0oa64u563urX5Q32u357/aln64ubj9lkdzef19357';
  private cycleMenuManagementStagingUrl =
    'https://staging.cmm-us.netmenu.cbord.com/NetMenu/auth/signin?facilityId=0&refresh=true';
  private cycleMenuManagementProdUrl =
    'https://cmm-us.netmenu.cbord.com/NetMenu/auth/signin?facilityId=0&refresh=true';
  private diningRdUrl =
    'https://app-gfs.diningmanager.com/home?domain_hint=gfs.com/';
  private readonly diningRdCaUrl =
    'https://app-gfs-can.diningmanager.com/home?domain_hint=gfs.com';
  private gordonRestaurantProStagingUrl = 'https://gfs-test.marginedge.com';
  private gordonRestaurantProProdUrl = 'https://gfs.marginedge.com/';
  private gordonReportingStagingUrlFr =
    /* eslint-disable-next-line max-len */
    'https://bisit.gfs.com/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=EC08668F4EC244A947CE608E81780221&Server=BUMBLEBEE&Project=Gordon%20Reporting&Port=34952&share=1';
  private gordonReportingProdUrlFr =
    /* eslint-disable-next-line max-len */
    'https://bi.gfs.com/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=EC08668F4EC244A947CE608E81780221&Server=WASP&Project=Gordon%20Reporting&Port=34952&share=1';
  private gordonReportingStagingUrlEn =
    /* eslint-disable-next-line max-len */
    'https://bisit.gfs.com/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=3233162D4A963846F9899AAF5309240E&Server=BUMBLEBEE&Project=Gordon%20Reporting&Port=34952&share=1';
  private gordonReportingProdUrlEn =
    /* eslint-disable-next-line max-len */
    'https://bi.gfs.com/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=3233162D4A963846F9899AAF5309240E&Server=WASP&Project=Gordon%20Reporting&Port=34952&share=1';
  private gordonEducationReportingStagingUrlEn =
    /* eslint-disable-next-line max-len */
    'https://bisit.gfs.com:443/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=B517943544E27E233798A493EA8B6B59&Server=BUMBLEBEE&Project=Gordon%20Reporting&Port=34952&share=1';
  private gordonEducationReportingProdUrlEn =
    /* eslint-disable-next-line max-len */
    'https://bi.gfs.com/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=B517943544E27E233798A493EA8B6B59&Server=WASP&Project=Gordon%20Reporting&Port=34952&share=1';
  private gordonHealthReportingStagingUrlEn =
    /* eslint-disable-next-line max-len */
    'https://bisit.gfs.com:443/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=9C1DA6BA4560036D0F2A85A4EDB1D3BA&Server=BUMBLEBEE&Project=Gordon%20Reporting&Port=34952&share=1';
  private gordonHealthReportingProdUrlEn =
    /* eslint-disable-next-line max-len */
    'https://bi.gfs.com/MicroStrategy/servlet/mstrWeb?evt=3140&src=mstrWeb.3140&documentID=9C1DA6BA4560036D0F2A85A4EDB1D3BA&Server=WASP&Project=Gordon%20Reporting&Port=34952&share=1';
  private googleMapsApiProdKey = 'AIzaSyC-rSV6NYMdbwKqNbJWkcZZGh0aEKrwnc8';
  private googleMapsApiStagingKey = 'AIzaSyB-BZC1csDjNnKpycnUCZexXqUPJ0SOrSQ';
  private readonly googleMapProdId = '840ea9cb5fc27896';
  private readonly googleMapStagingId = '8b5eba9650d75d9d';
  private environmentSpecific: NaooEnvironments = {
    wiremock: {
      logRocketAppId: '',
      adZerkUrl: this.adZerkWiremock,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
        experience: this.experienceStagingUrl,
        resources: this.resourcesStagingUrl,
        online_payment: this.onlinePaymentStagingUrl,
        cycle_menu_management: this.cycleMenuManagementStagingUrl,
        dining_rd: this.diningRdUrl,
        dining_rd_ca: this.diningRdCaUrl,
        gordon_restaurant_pro: this.gordonRestaurantProStagingUrl,
        gordon_reporting: this.gordonReportingStagingUrlEn,
        gordon_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_education_reporting: this.gordonEducationReportingStagingUrlEn,
        gordon_education_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_health_reporting: this.gordonHealthReportingStagingUrlEn,
        gordon_health_reporting_fr: this.gordonReportingStagingUrlFr,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
      googleMapId: this.googleMapStagingId,
    },
    local: {
      logRocketAppId: '',
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
        experience: this.experienceStagingUrl,
        resources: this.resourcesStagingUrl,
        online_payment: this.onlinePaymentStagingUrl,
        cycle_menu_management: this.cycleMenuManagementStagingUrl,
        dining_rd: this.diningRdUrl,
        dining_rd_ca: this.diningRdCaUrl,
        gordon_restaurant_pro: this.gordonRestaurantProStagingUrl,
        gordon_reporting: this.gordonReportingStagingUrlEn,
        gordon_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_education_reporting: this.gordonEducationReportingStagingUrlEn,
        gordon_education_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_health_reporting: this.gordonHealthReportingStagingUrlEn,
        gordon_health_reporting_fr: this.gordonReportingStagingUrlFr,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
      googleMapId: this.googleMapStagingId,
    },
    dev: {
      logRocketAppId: this.logRocketPrefix + Environment.dev,
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
        experience: this.experienceStagingUrl,
        resources: this.resourcesStagingUrl,
        online_payment: this.onlinePaymentStagingUrl,
        cycle_menu_management: this.cycleMenuManagementStagingUrl,
        dining_rd: this.diningRdUrl,
        dining_rd_ca: this.diningRdCaUrl,
        gordon_restaurant_pro: this.gordonRestaurantProStagingUrl,
        gordon_reporting: this.gordonReportingStagingUrlEn,
        gordon_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_education_reporting: this.gordonEducationReportingStagingUrlEn,
        gordon_education_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_health_reporting: this.gordonHealthReportingStagingUrlEn,
        gordon_health_reporting_fr: this.gordonReportingStagingUrlFr,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
      googleMapId: this.googleMapStagingId,
    },
    tst: {
      logRocketAppId: this.logRocketPrefix + Environment.tst,
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
        experience: this.experienceStagingUrl,
        resources: this.resourcesStagingUrl,
        online_payment: this.onlinePaymentStagingUrl,
        cycle_menu_management: this.cycleMenuManagementStagingUrl,
        dining_rd: this.diningRdUrl,
        dining_rd_ca: this.diningRdCaUrl,
        gordon_restaurant_pro: this.gordonRestaurantProStagingUrl,
        gordon_reporting: this.gordonReportingStagingUrlEn,
        gordon_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_education_reporting: this.gordonEducationReportingStagingUrlEn,
        gordon_education_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_health_reporting: this.gordonHealthReportingStagingUrlEn,
        gordon_health_reporting_fr: this.gordonReportingStagingUrlFr,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
      googleMapId: this.googleMapStagingId,
    },
    qat: {
      logRocketAppId: this.logRocketPrefix + Environment.qat,
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
        experience: this.experienceStagingUrl,
        resources: this.resourcesStagingUrl,
        online_payment: this.onlinePaymentStagingUrl,
        cycle_menu_management: this.cycleMenuManagementStagingUrl,
        dining_rd: this.diningRdUrl,
        dining_rd_ca: this.diningRdCaUrl,
        gordon_restaurant_pro: this.gordonRestaurantProStagingUrl,
        gordon_reporting: this.gordonReportingStagingUrlEn,
        gordon_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_education_reporting: this.gordonEducationReportingStagingUrlEn,
        gordon_education_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_health_reporting: this.gordonHealthReportingStagingUrlEn,
        gordon_health_reporting_fr: this.gordonReportingStagingUrlFr,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
      googleMapId: this.googleMapStagingId,
    },
    sit: {
      logRocketAppId: this.logRocketPrefix + Environment.sit,
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.sit,
      headerUrls: {
        recipe: this.recipeStagingUrl,
        inventory: this.inventoryStagingUrl,
        experience: this.experienceStagingUrl,
        resources: this.resourcesStagingUrl,
        online_payment: this.onlinePaymentStagingUrl,
        cycle_menu_management: this.cycleMenuManagementStagingUrl,
        dining_rd: this.diningRdUrl,
        dining_rd_ca: this.diningRdCaUrl,
        gordon_restaurant_pro: this.gordonRestaurantProStagingUrl,
        gordon_reporting: this.gordonReportingStagingUrlEn,
        gordon_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_education_reporting: this.gordonEducationReportingStagingUrlEn,
        gordon_education_reporting_fr: this.gordonReportingStagingUrlFr,
        gordon_health_reporting: this.gordonHealthReportingStagingUrlEn,
        gordon_health_reporting_fr: this.gordonReportingStagingUrlFr,
      },
      googleMapsApiKey: this.googleMapsApiStagingKey,
      googleMapId: this.googleMapStagingId,
    },
    prd: {
      logRocketAppId: this.logRocketPrefix + Environment.prd,
      adZerkUrl: this.adZerkUrl,
      chatChannelEnv: Environment.prd,
      headerUrls: {
        recipe: this.recipeProdUrl,
        inventory: this.inventoryProdUrl,
        experience: this.experienceProdUrl,
        resources: this.resourcesProdUrl,
        online_payment: this.onlinePaymentProdUrl,
        cycle_menu_management: this.cycleMenuManagementProdUrl,
        dining_rd: this.diningRdUrl,
        dining_rd_ca: this.diningRdCaUrl,
        gordon_restaurant_pro: this.gordonRestaurantProProdUrl,
        gordon_reporting: this.gordonReportingProdUrlEn,
        gordon_reporting_fr: this.gordonReportingProdUrlFr,
        gordon_education_reporting: this.gordonEducationReportingProdUrlEn,
        gordon_education_reporting_fr: this.gordonReportingProdUrlFr,
        gordon_health_reporting: this.gordonHealthReportingProdUrlEn,
        gordon_health_reporting_fr: this.gordonReportingProdUrlFr,
      },
      googleMapsApiKey: this.googleMapsApiProdKey,
      googleMapId: this.googleMapProdId,
    },
  };

  constructor(private _window: Window) {}

  getLogRocketAppId(): string {
    const env = this.getEnvironment();
    return this.environmentSpecific[env].logRocketAppId;
  }

  getLogRocketChildDomain(): string {
    const environmentProperty = this.isProduction()
      ? GordonNowProperties.prd
      : GordonNowProperties.sit;
    return new URL(environmentProperty.url).origin;
  }

  getGoogleMapsApiKey(): string {
    const env = this.getEnvironment();
    return this.environmentSpecific[env].googleMapsApiKey;
  }

  getGoogleMapsId(): string {
    return this.environmentSpecific[this.getEnvironment()].googleMapId;
  }

  getAdZerkUrl(): string {
    const env = this.getEnvironment();
    return this.environmentSpecific[env].adZerkUrl;
  }

  getHeaderUrl(name: HeaderLinkType): string {
    const env = this.getEnvironment();
    return this.environmentSpecific[env].headerUrls[name];
  }

  getOnlinePaymentUrl(): string {
    if (this.isProduction()) {
      return this.onlinePaymentProdUrl;
    } else {
      return this.onlinePaymentStagingUrl;
    }
  }

  getEcomContentUrl(): string {
    if (this.isProduction()) {
      return this.ecomContentProdUrl;
    } else {
      return this.ecomContentStagingUrl;
    }
  }

  getAciUrl(): string {
    if (this.isProduction()) {
      return this.aciPaymentsProdUrl;
    } else {
      return this.aciPaymentsStagingUrl;
    }
  }

  getGordonNowChatChannel(customerBrand: CustomerBrand): string {
    const brand = customerBrand
      .substr(customerBrand.indexOf('_') + 1)
      .toLowerCase();
    const env = this.getEnvironment();
    return `cx-gordon-ordering-${brand}-${this.environmentSpecific[env].chatChannelEnv}`;
  }

  getGordonNowChatScriptAttributes(): Map<string, string> {
    const scriptAttributes = new Map<string, string>();
    scriptAttributes.set('id', 'gordon-now-chat-env');
    scriptAttributes.set('data-manualInit', 'true');
    scriptAttributes.set('crossorigin', 'anonymous');
    const environmentProperty = this.isProduction()
      ? GordonNowProperties.prd
      : GordonNowProperties.sit;
    scriptAttributes.set('src', environmentProperty.url);
    scriptAttributes.set('integrity', environmentProperty.sri);

    return scriptAttributes;
  }

  isGordonNowEnabled(): boolean {
    return ![Environment.local, Environment.wiremock].includes(
      this.getEnvironment(),
    );
  }

  isProduction(): boolean {
    return this.getEnvironment() === Environment.prd;
  }

  isWireMock(): boolean {
    return this.getEnvironment() === Environment.wiremock;
  }

  isUnitTest(): boolean {
    return false;
  }

  private getEnvironment(): Environment | null {
    if (this._window.location.hostname.endsWith('orderdev.gfs.com')) {
      return Environment.dev;
    } else if (this._window.location.hostname.endsWith('ordertst.gfs.com')) {
      return Environment.tst;
    } else if (this._window.location.hostname.endsWith('orderqat.gfs.com')) {
      return Environment.qat;
    } else if (this._window.location.hostname.endsWith('ordersit.gfs.com')) {
      return Environment.sit;
    } else if (this._window.location.hostname.endsWith('order.gfs.com')) {
      return Environment.prd;
    } else if (!environment.usesWiremock) {
      return Environment.local;
    } else {
      return Environment.wiremock;
    }
  }
}
