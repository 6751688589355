import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import moment, { ISO_8601 } from 'moment-timezone';
import { InvoiceCredit } from '../../../shared/models/invoice-credit';
import { InvoicesCreditsService } from '../../../shared/services/invoices-credits/invoices-credits.service';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { LoadingService } from '../../../shared/services/loading-service/loading.service';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { ExportModalResponse } from '../../../shared/modals/export-modal/export-modal.component';
import { ExportModalDataPoint } from '../../../shared/models/export-modal-data-point';
import { InvoiceDetail } from '../../../shared/models/invoice-detail';
import { ExportInvoiceDetail } from '../../../shared/models/export/export-invoice-detail';
import { ExportService } from '../../../shared/services/export-service/export.service';
import { ToastMessageService } from '../../../shared/services/toast-message/toast-message.service';
import {
  ExportDataPoint,
  ExportFeatureType,
  ExportFileType,
} from '../../../shared/models/export/export-properties';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { OrderLine } from '../../../shared/models/order-line';
import { DeviceIdentifierService } from 'src/app/shared/services/device-identifier/device-identifier.service';
import { AsyncPipe, formatDate } from '@angular/common';
import { dateFormats } from 'src/app/shared/utilities/date-utilities';
import { LocalizedUtilities } from 'src/app/shared/utilities/localized-utilities';
import { CurrentSystem } from '../../../core/services/session/models/session-record';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { DateRange } from '../../../shared/date-range-selector/date-range';
import { DateRangeSelectorComponent } from '../../../shared/date-range-selector/date-range-selector.component';
import { SearchFieldComponent } from '../../../shared/search-bar/search-field.component';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NoResultsComponent } from '../../../shared/no-results/no-results.component';
import { ErrorMessageComponent } from '../../../shared/error-message/error-message.component';
import { NaooPricePipe } from '../../../shared/pipes/naoo-price.pipe';
import { NaooDatePipe } from '../../../shared/pipes/naoo-date.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-invoices-credits',
  templateUrl: './invoices-credits.component.html',
  styleUrls: ['./invoices-credits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DateRangeSelectorComponent,
    SearchFieldComponent,
    MatIcon,
    RouterLink,
    NoResultsComponent,
    ErrorMessageComponent,
    AsyncPipe,
    NaooPricePipe,
    NaooDatePipe,
    TranslateModule,
  ],
})
export class InvoicesCreditsComponent implements OnInit, OnDestroy {
  protected readonly ANALYTICS_VIEW_ACTION = 'View Document';
  protected readonly INVOICES_PATH = InvoicesCreditsService.INVOICES_PATH;

  protected filteredInvoices$: Observable<InvoiceCredit[]>;
  protected isMobile$: Observable<boolean> =
    this.deviceIdentifierService.observeDeviceType();
  hasInvoicesCreditsError = false;
  protected searchText$ = new BehaviorSubject<string>('');

  private readonly nonBreakingSpace = /[\u202F\u00A0]/;
  private readonly invoiceExportFileName = 'EXPORT.EXPORT_FILE_NAME';
  private readonly analyticsSelectMonthFormat = 'MMM';
  private readonly defaultExportList = [
    {
      name: ExportDataPoint.ItemNumber,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.ITEM_NUMBER',
      isSelected: true,
    },
    {
      name: ExportDataPoint.ItemDescription,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.ITEM_DESCRIPTION',
      isSelected: true,
    },
    {
      name: ExportDataPoint.Pack,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PACK',
      isSelected: true,
    },
    {
      name: ExportDataPoint.PackUnit,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PACK_UNIT',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Size,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.SIZE',
      isSelected: true,
    },
    {
      name: ExportDataPoint.QuantityShipped,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QUANTITY_SHIPPED',
      isSelected: true,
    },
    {
      name: ExportDataPoint.CasePrice,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PRICE_CASE_UNIT',
      isSelected: true,
    },
    {
      name: ExportDataPoint.ExtendedPrice,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PRICE_EXTENDED',
      isSelected: true,
    },
    {
      name: ExportDataPoint.Gtin,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.GTIN',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Unit,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.UNIT',
      isSelected: false,
    },
    {
      name: ExportDataPoint.QuantityOrdered,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QUANTITY_ORDERED',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Brand,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.BRAND',
      isSelected: false,
    },
  ];
  private readonly destroyed$ = new Subject<void>();

  private translatedCreditString = '';
  private translatedInvoiceString = '';
  private translatedDebitString = '';
  private invoiceCredits$: Observable<InvoiceCredit[]>;
  private firstDateRangeLoad = true;
  selectedDateRange: DateRange;

  // eslint-disable-next-line max-params
  constructor(
    private readonly invoicesCreditsService: InvoicesCreditsService,
    private readonly loadingService: LoadingService,
    private readonly analyticsManager: NaooAnalyticsManager,
    private readonly customDialogService: CustomDialogService,
    private readonly exportService: ExportService,
    private readonly toastMessageService: ToastMessageService,
    private readonly localizationService: LocalizationService,
    private readonly sessionFacade: SessionFacade,
    private readonly deviceIdentifierService: DeviceIdentifierService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        this.translatedCreditString = this.localizationService.instant(
          'ORDERS.INVOICES_CREDITS.CREDIT',
        );
        this.translatedInvoiceString = this.localizationService.instant(
          'ORDERS.INVOICES_CREDITS.INVOICE',
        );
        this.translatedDebitString = this.localizationService.instant(
          'ORDERS.INVOICES_CREDITS.DEBIT',
        );
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onChangeDate(event?: DateRange): void {
    this.selectedDateRange = event;

    if (this.firstDateRangeLoad) {
      this.firstDateRangeLoad = false;
    } else {
      this.trackAnalytics('Selected', this.createDateChangeAnalyticsLabel());
    }

    this.loadingService.start();
    this.hasInvoicesCreditsError = false;
    this.changeDetectorRef.markForCheck();

    this.updateInvoiceCreditsObservable();
  }

  updateSearchText(searchText: string): void {
    this.searchText$.next(searchText);
  }

  handleExportAction(invoiceCredit: InvoiceCredit): void {
    this.openExportModal(invoiceCredit);
  }

  sendAnalytics(invoiceCredit: InvoiceCredit, actionType: string): void {
    let eventLabel: string;
    if (invoiceCredit.debitInvoice) {
      eventLabel = 'Debit PDF';
    } else {
      eventLabel = invoiceCredit.creditOrder ? 'Credit PDF' : 'Invoice PDF';
    }

    this.trackAnalytics(actionType, eventLabel);
  }

  getTypeLabel(invoiceCredit: InvoiceCredit): string {
    if (invoiceCredit.debitInvoice) {
      return 'ORDERS.INVOICES_CREDITS.DEBIT';
    }
    return invoiceCredit.creditOrder
      ? 'ORDERS.INVOICES_CREDITS.CREDIT'
      : 'ORDERS.INVOICES_CREDITS.INVOICE';
  }

  openExportModal(invoiceCredit: InvoiceCredit): void {
    let dataPoints: ExportModalDataPoint[];
    let fileFormat: ExportFileType;

    // openExportDialog return an observable of ExportModalResponse which contain the file format and the selected data points
    this.openExportDialog(invoiceCredit.creditOrder, invoiceCredit.debitInvoice)
      .pipe(
        first(),
        concatMap((exportModalResponse) => {
          if (!!exportModalResponse && !!exportModalResponse.dataPoints) {
            dataPoints = exportModalResponse.dataPoints;
            fileFormat = exportModalResponse.fileFormat;
            // we call to backend to get the invoice details and product Info
            return this.getInvoiceDetails(invoiceCredit);
          } else {
            // concatMap need to have a return of an observable. We use the null value in the subscribe
            // to check if export modal didn't return values
            return of(null);
          }
        }),
      )
      .subscribe((invoiceDetail: InvoiceDetail) => {
        const invoiceExportData: ExportInvoiceDetail[] = [];
        if (invoiceDetail) {
          invoiceDetail.invoiceOrderLines.forEach((orderLine) =>
            invoiceExportData.push(...this.separateByOrderLine(orderLine)),
          );
          const exportData = this.generateExportData(
            invoiceExportData,
            dataPoints,
          );
          this.doExport(fileFormat, invoiceCredit, exportData);
        }
      });
  }

  private doesInvoicePassFilter(
    invoice: InvoiceCredit,
    searchText: string,
  ): boolean {
    if (!searchText) {
      return true;
    }

    const caseInsensitiveSearchText = searchText
      .toLowerCase()
      .replace(this.nonBreakingSpace, ' ');

    let invoiceTypeSearchableProperty: string;
    if (invoice.debitInvoice) {
      invoiceTypeSearchableProperty = this.translatedDebitString;
    } else {
      invoiceTypeSearchableProperty = invoice.creditOrder
        ? this.translatedCreditString
        : this.translatedInvoiceString;
    }
    const currentLocale = this.localizationService.currentLocale;
    const searchablePrice =
      LocalizedUtilities.getLocalizedPrice(
        currentLocale,
        invoice.invoiceAmount,
      )?.replace(this.nonBreakingSpace, ' ') ?? '-';

    const searchableProperties = [
      formatDate(
        invoice.transactionDate,
        dateFormats[currentLocale].shortDate,
        currentLocale,
      ),
      invoice.transactionNumber,
      invoice.poNumber != null ? invoice.poNumber : '',
      searchablePrice,
      invoice.invoiceAmount,
      invoiceTypeSearchableProperty,
    ].map((property) => property.toString().toLowerCase());

    return searchableProperties.some((property) =>
      property.includes(caseInsensitiveSearchText),
    );
  }

  /**
   * Call to getInvoiceDetails to get the orderLines with productInfo on it
   * @param invoiceCredit
   */
  private getInvoiceDetails(
    invoiceCredit: InvoiceCredit,
  ): Observable<InvoiceDetail> {
    return this.invoicesCreditsService.getInvoiceDetails(
      invoiceCredit.transactionNumber.toString(),
      invoiceCredit.transactionDate,
    );
  }

  private doExport(
    fileFormat: ExportFileType,
    invoiceCredit: InvoiceCredit,
    exportData: unknown[],
  ): void {
    this.showToastMessage();
    switch (fileFormat) {
      case ExportFileType.CSV:
        this.exportService.downloadCsv(
          exportData,
          this.localizationService.instant(this.invoiceExportFileName, {
            orderNumber: invoiceCredit.transactionNumber,
          }),
        );
        break;
      case ExportFileType.EXCEL:
        this.exportService.downloadXlsx(
          exportData,
          this.localizationService.instant(this.invoiceExportFileName, {
            orderNumber: invoiceCredit.transactionNumber,
          }),
        );
        break;
      default:
        break;
    }
  }

  private showToastMessage(): void {
    this.toastMessageService.showToastMessage(
      this.localizationService.instant('EXPORT_MODAL.TOAST_MESSAGE'),
    );
  }

  private generateExportData(
    exportInvoiceDetail: ExportInvoiceDetail[],
    dataPoints: ExportModalDataPoint[],
  ) {
    const exportData: unknown[] = [];
    exportData.push(this.getCsvHeaders(dataPoints));
    exportInvoiceDetail.forEach((item) => {
      const exportObject: string[] = [];
      dataPoints.forEach((data) => {
        const dataPoint = item.getItem(data.name);
        exportObject.push(dataPoint ? dataPoint.toString() : '');
      });
      exportData.push(exportObject);
    });
    return exportData;
  }

  private getCsvHeaders(dataPoints: ExportModalDataPoint[]): string[] {
    const headers: string[] = [];
    dataPoints.forEach((dataPoint) => {
      headers.push(this.localizationService.instant(dataPoint.label));
    });
    return headers;
  }

  private trackAnalytics(eventAction: string, eventLabel: string): void {
    const eventInfo: AnalyticsEventInfo = {
      action: eventAction,
      category: 'Invoices/Credits Display',
      label: eventLabel,
    };

    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private createDateChangeAnalyticsLabel(): string {
    if (this.selectedDateRange?.label === 'DATE_SELECTOR.LAST_100_DAYS') {
      return 'Last 100 Days';
    }

    return `${this.selectedDateRange.year} ${moment(
      this.selectedDateRange.startDate,
    ).format(this.analyticsSelectMonthFormat)} - ${moment(
      this.selectedDateRange.endDate,
    ).format(this.analyticsSelectMonthFormat)}`;
  }

  private openExportDialog(
    creditOrder: boolean,
    debitInvoice: boolean,
  ): Observable<ExportModalResponse> {
    let title: string;
    let id: string;
    if (debitInvoice) {
      title = 'EXPORT_MODAL.EXPORT_DEBIT.DETAILS';
      id = 'export-debit';
    } else {
      title = creditOrder
        ? 'EXPORT_MODAL.EXPORT_CREDIT.DETAILS'
        : 'EXPORT_MODAL.EXPORT_INVOICE.DETAILS';
      id = creditOrder ? 'export-credit' : 'export-invoice';
    }
    return this.customDialogService
      .exportModal(
        id,
        title,
        [
          {
            name: ExportFileType.EXCEL,
            value: 'excel',
            isChecked: true,
            i18nTag: 'EXPORT_MODAL.EXCEL',
          },
          {
            name: ExportFileType.CSV,
            value: 'csv',
            isChecked: false,
            i18nTag: 'EXPORT_MODAL.CSV',
          },
        ],
        'Invoice/Credits Display',
        this.getDataPointsBasedOnCurrentSystem(),
        ExportFeatureType.INVOICE_DETAILS,
      )
      .pipe(
        switchMap((dialog) => {
          return dialog.afterClosed();
        }),
      );
  }

  private getDataPointsBasedOnCurrentSystem(): ExportModalDataPoint[] {
    let defaultExportList: ExportModalDataPoint[] = [];
    this.sessionFacade
      .getLoadedCurrentSystem()
      .pipe(first())
      .subscribe((currentSystem) => {
        if (currentSystem === CurrentSystem.Sap) {
          defaultExportList = this.defaultExportList.concat([
            {
              name: ExportDataPoint.PurchaseUnit,
              label: 'EXPORT_MODAL.EXPORT_ITEMS.PURCHASE_UNIT',
              isSelected: false,
            },
            {
              name: ExportDataPoint.CatchWeight,
              label: 'EXPORT_MODAL.EXPORT_ITEMS.CATCH_WEIGHT',
              isSelected: false,
            },
            {
              name: ExportDataPoint.TotalCatchWeightActualWeight,
              label:
                'EXPORT_MODAL.EXPORT_ITEMS.TOTAL_CATCH_WEIGHT_ACTUAL_WEIGHT',
              isSelected: false,
            },
          ]);
        } else {
          defaultExportList = this.defaultExportList;
        }
      });

    return defaultExportList;
  }

  private separateByOrderLine(orderLine: OrderLine): ExportInvoiceDetail[] {
    const isStockShort: boolean =
      orderLine.quantityShipped < orderLine.quantityOrdered;

    return this.processAsIndividualOrderLine(orderLine, isStockShort);
  }

  private processAsIndividualOrderLine(
    orderLine: OrderLine,
    isStockShort: boolean,
  ): ExportInvoiceDetail[] {
    const exportInvoiceDetails: ExportInvoiceDetail[] = [];
    if (isStockShort) {
      const shippedItemsLine = Object.assign({}, orderLine);
      shippedItemsLine.quantityOrdered = orderLine.quantityShipped;
      if (shippedItemsLine.quantityOrdered !== 0) {
        exportInvoiceDetails.push(
          new ExportInvoiceDetail(
            this.localizationService.currentLanguage,
            shippedItemsLine,
          ),
        );
      }
      exportInvoiceDetails.push(
        this.createNonSentItemsReportInvoiceOrderLine(orderLine),
      );
    } else {
      exportInvoiceDetails.push(
        new ExportInvoiceDetail(
          this.localizationService.currentLanguage,
          orderLine,
        ),
      );
    }
    return exportInvoiceDetails;
  }

  private createSingleOrderLineForEachCase(orderLine: OrderLine): OrderLine {
    return {
      ...orderLine,
      quantityShipped: 1,
      quantityOrdered: 1,
    };
  }

  private createNonSentItemsReportInvoiceOrderLine(
    orderLine: OrderLine,
  ): ExportInvoiceDetail {
    const nonSentItems = this.createSingleOrderLineForEachCase(orderLine);
    nonSentItems.total = 0;
    nonSentItems.quantityShipped = 0;
    nonSentItems.quantityOrdered =
      orderLine.quantityOrdered - orderLine.quantityShipped;
    return new ExportInvoiceDetail(
      this.localizationService.currentLanguage,
      nonSentItems,
    );
  }

  private updateInvoiceCreditsObservable(): void {
    const startDate: Date = moment(
      this.selectedDateRange?.startDate,
      ISO_8601,
    ).toDate();

    const endDate: Date =
      this.selectedDateRange?.label !== 'DATE_SELECTOR.LAST_100_DAYS'
        ? moment(this.selectedDateRange?.endDate, ISO_8601).toDate()
        : undefined;

    this.invoiceCredits$ = this.invoicesCreditsService
      .getHistory(startDate, endDate)
      .pipe(
        catchError(() => {
          this.hasInvoicesCreditsError = true;
          this.trackAnalytics('Error', 'Table Unavailable');
          return EMPTY;
        }),
        finalize(() => {
          this.loadingService.stop();
        }),
        map((response) => (response?.invoices ? response.invoices : [])),
      );

    this.filteredInvoices$ = combineLatest([
      this.invoiceCredits$,
      this.searchText$,
    ]).pipe(
      map(([invoices, searchText]) => {
        this.changeDetectorRef.markForCheck();
        return invoices.filter((invoice) =>
          this.doesInvoicePassFilter(invoice, searchText),
        );
      }),
    );
  }
}
