import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionFacade } from '../../core/store/session/session.facade';
import { SessionActiveCustomer } from '../../core/services/session/models/session-record';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-customer-unit-info',
  templateUrl: './customer-unit-info.component.html',
  styleUrls: ['./customer-unit-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, AsyncPipe],
})
export class CustomerUnitInfoComponent {
  protected customer$: Observable<SessionActiveCustomer> =
    this.sessionFacade.getLoadedActiveCustomer();

  constructor(private readonly sessionFacade: SessionFacade) {}
}
