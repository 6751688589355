@if (customer$ | async; as customer) {
  <div
    [attr.aria-label]="
      [
        customer.name,
        'CUSTOMER_UNIT.ALT_CUSTOMER_UNIT' | translate,
        customer.customerDisplayId,
      ].join(' ')
    "
    class="customer-info"
  >
    <div class="customer-name">{{ customer.name }}</div>
    <div class="customer-number">#{{ customer.customerDisplayId }}</div>
  </div>
}
